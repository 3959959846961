import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Card,
  CardContent
} from '@mui/material';
import { NewsType } from '../../types/types';

type NewsItemsModalProps = {
  open: boolean;
  onClose: () => void;
  newsItems: NewsType[];
  modalTitle: string;
};

const NewsItemsModal: React.FC<NewsItemsModalProps> = ({ open, onClose, newsItems, modalTitle }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{`「${modalTitle}」に関連するニュース`}</DialogTitle>
      <DialogContent dividers>
        {newsItems.length > 0 ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 2,
              alignItems: 'stretch'
            }}
          >
            {newsItems.map((news) => (
              <Card key={news.id} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <CardContent>
                  <Typography
                    variant="h6"
                    component="a"
                    href={news.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'none',
                      color: 'primary.main',
                      fontWeight: 'bold',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    {news.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                    {news.releasedTimestamp} - {news.websiteTitle}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>{news.summary}</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {news.keywords.map((keyword) => (
                      <Chip key={keyword} label={keyword} variant="outlined" />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
        ) : (
          <Typography variant="body2">このキーワードに関連するニュースはありません。</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewsItemsModal;
