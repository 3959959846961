import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Button,
  Box,
  DialogActions,
} from "@mui/material";

interface RenameGroupDialogProps {
  open: boolean;
  onClose: () => void;
  currentGroupName: string;
  onConfirm: (newName: string) => void;
}

const RenameGroupDialog: React.FC<RenameGroupDialogProps> = ({
  open,
  onClose,
  currentGroupName,
  onConfirm,
}) => {
  const [newGroupName, setNewGroupName] = useState("");


  useEffect(() => {
    setNewGroupName(currentGroupName);
  }, [currentGroupName]);
  
  const handleConfirm = async () => {
    try {
        await onConfirm(newGroupName); // Wait for the confirmation function to complete
        onClose(); // Close the dialog only if `onConfirm` succeeds
        setNewGroupName(""); // Reset the input field
    } catch (error) {
        console.error("Error during group rename:", error);
        // Optionally, show an error message to the user
    }
};

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth >
      <DialogTitle sx={{
        padding: '16px 24px 0px 24px',
        textAlign: 'left',
        color: '#656572',
        fontSize: '16px',
        fontWeight: 500
      }}>コレクション名を変更する</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: '24px',
        mt: '24px'
      }}>
        <Box mb={0}>
          <Typography sx={{
            color: '#9797A3',
            fontSize: '12px'
          }}>
            現在のコレクション名
          </Typography>
          <Typography sx={{
            fontWeight: 700,
            color: "#17171A",
            fontSize: "18px"
          }}>
            {currentGroupName}
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography sx={{
            color: '#9797A3',
            fontSize: '12px'
          }}>
            新しいコレクション名を入力
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={newGroupName}
            onChange={(e) => setNewGroupName(e.target.value)}
            sx={{
              borderRadius: "var(--borderRadius, 4px)",
              "& .MuiOutlinedInput-root": {
                border: "1px solid var(--BK-400, #B9B9C1)",
                borderRadius: "var(--borderRadius, 4px)",
                "&.Mui-focused": {
                  borderColor: "var(--BK-10000, #17171A)",
                },
                "& input": {
                  padding: "16px 12px",
                  color: "var(--BK-10000, #17171A)",
                  fontSize: "18px",
                  fontWeight: 700,
                },
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0px'
      }}>
        <Button onClick={onClose} sx={{ color: "#27272C" }}>
          キャンセル
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          sx={{
            backgroundColor: "#F4C542",
            color: "#000",
          }}
        >
          確定
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default RenameGroupDialog;