import { styled, Switch } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';


export const ToggleSwitch = styled(Switch)(({ theme }) => ({
    padding: '0px 12px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#F1D04C',
        '& + .MuiSwitch-track': {
          backgroundColor: '#F1D04C',
        },
      },
  },
    '& .MuiSwitch-thumb': {
      width: '20px',
      height: '20px',
      // marginLeft: '-12px',
      filter: 'drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))',
      '&.Mui-checked': {
        backgroundColor: '#F1D04C', // オン時のKnobの色
      },
    },
    '& .MuiSwitch-track': {
      width: '34px',
      height: '14px',
      borderRadius: '100px',
      backgroundColor: '#CBCBD0',
      display: 'flex',
      alignItems: 'center', // KnobとTrackの中央を揃える
      // overflow: 'visible'
    },
  }));