import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'pricing-table-id': string;
        'publishable-key': string;
        'customer-session-client-secret'?: string;
      };
    }
  }
}

interface PricingTableProps {
  open: boolean;
  onClose: () => void;
  clientSecret: string;
}

const PricingTable: React.FC<PricingTableProps> = ({ clientSecret, open, onClose }) => {
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
  const pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID || '';

  useEffect(() => {
    // Dynamically load the Stripe Pricing Table script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '60vw',       // Set a wider width (90% of viewport width)
          height: '60vh',      // Reduce height to 70% of viewport height
          maxWidth: 'unset',   // Prevent default MUI width restrictions
          maxHeight: 'unset',  // Prevent default height restrictions
          margin: 'auto',      // Center the dialog
          borderRadius: '8px', // Optional: Add rounded corners
          overflow: 'hidden',  // Prevent content overflow
        },
      }}
    >
      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table pricing-table-id={pricingTableId} publishable-key={publishableKey} customer-session-client-secret={clientSecret}></stripe-pricing-table>
    </Dialog>
  );
};

export default PricingTable;