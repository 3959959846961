import React from 'react';
import { Menu, MenuItem, Typography, Divider } from '@mui/material';
import { RoleType } from '../../types/types';
import { useGroup } from '../../GroupContext';
import { SnackbarType } from '../../types/types';
import {
    Dispatch
} from 'react';
import { SetStateAction } from 'react';

interface CollectionSubMenuProps {
    menuAnchorEl: HTMLElement | null;
    isMenuOpen: boolean;
    handleMenuClose: () => void;
    handleRenameGroup: () => void;
    handleDeleteClick: () => void;
    role: RoleType;
    setSnackbarState: Dispatch<SetStateAction<SnackbarType>>
}

const CollectionSubMenu: React.FC<CollectionSubMenuProps> = ({
    menuAnchorEl,
    isMenuOpen,
    handleMenuClose,
    handleRenameGroup,
    handleDeleteClick,
    role,
    setSnackbarState
}) => {
    const { planName } = useGroup()
    return (
        <Menu
            anchorEl={menuAnchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: -10,
                horizontal: 'right'
            }}
            MenuListProps={{
                sx: {
                    backgroundColor: "#46464F",
                    padding: "24px",
                    borderRadius: "6px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: "12px",
                },
            }}
            slotProps={{
                paper: {
                    sx: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                },
            }}
        >
            <MenuItem
                onClick={handleRenameGroup}
                disableGutters
                sx={{
                    padding: "12px 8px",
                    borderRadius: "var(--borderRadius, 4px)",
                    "&:hover": {
                        background: "rgba(255, 255, 255, 0.08)",
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                        background: "rgba(255, 255, 255, 0.08)",
                        borderRadius: "var(--borderRadius, 4px)",
                    },
                }}
            >
                <Typography
                    sx={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#FFF",
                        textAlign: "left",
                        width: "196px"
                    }}
                >
                    コレクション名を変更する
                </Typography>
            </MenuItem>
            {role == "admin" && (<div>
                <Divider
                    sx={{
                        backgroundColor: "#656572",
                        marginTop: "0px !important",
                        marginBottom: "0px !important",
                    }}
                />
                <MenuItem
                    onClick={handleDeleteClick}
                    disableGutters
                    sx={{
                        padding: "12px 8px",
                        borderRadius: "var(--borderRadius, 4px)",
                        "&:hover": {
                            background: "rgba(255, 255, 255, 0.08)",
                        },
                        "&.Mui-selected, &.Mui-selected:hover": {
                            background: "rgba(255, 255, 255, 0.08)",
                            borderRadius: "var(--borderRadius, 4px)",
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: "13px",
                            color: "#FFF",
                            textAlign: "left",
                            width: "100%",
                        }}
                    >
                        コレクションを削除する
                    </Typography>
                </MenuItem>

            </div>)}
        </Menu>
    );
};

export default CollectionSubMenu;
