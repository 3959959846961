import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignupRedirect: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
        screen_hint: "signup", // Show the signup form
      },
    });
  }, [loginWithRedirect]);

  return <p>Redirecting to signup...</p>; // Optional message while redirecting
};

export default SignupRedirect;