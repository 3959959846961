import { RoleType } from "../../types/types";
import { Box, Typography } from "@mui/material"

interface CollectionRoleChipProps {
    role: RoleType;
}

const roleStyles: Record<RoleType, { backgroundColor: string; textColor: string; border?: string }> = {
    admin: { backgroundColor: '#9A46CB', textColor: '#FFF' },
    editor: { backgroundColor: '#0360FC', textColor: '#FFF' },
    viewer: { backgroundColor: '#FFF', textColor: '#747483', border: '1px solid #747483' },
};


const CollectionRoleChip: React.FC<CollectionRoleChipProps> = ({ role }) => {
    const { backgroundColor, textColor, border } = roleStyles[role];

    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '24px',
                maxHeight: '24px',
                padding: '3px 4px',
                alignItems: 'center',
                borderRadius: '100px',
                backgroundColor,
                border,
            }}
        >
            <Typography
                sx={{
                    color: textColor,
                    fontSize: '14px',
                    padding: '0px 6px',
                    fontFamily: 'Roboto'
                }}
            >
                {role === 'admin' ? '管理者' : role === 'editor' ? '編集者' : '閲覧者'}
            </Typography>
        </Box>
    );
};

export default CollectionRoleChip
