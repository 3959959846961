import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
  Popover
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useGroup } from '../../GroupContext';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarType } from '../../types/types';
import RenameGroupDialog from './RenameGroupDialog';
import { GroupInfo } from '../../GroupContext';
import { ReactComponent as MoreVertIcon } from '../../assets/icons/VerticalEllipsisButton.svg'
import CollectionRoleChip from './CollectionRoleChip';
import DeleteCollectionDialog from './DeleteCollectionDialog';
import CollectionSubMenu from './CollectionSubMenu';
import NotificationSnackbar from '../shared/NotificationSnackbar';
import { group } from 'console';

interface CollectionsInfoDisplayProps {
  open: boolean;
  onClose: () => void;
  groupInfo: GroupInfo[];
}

const CollectionsInfoDisplay: React.FC<CollectionsInfoDisplayProps> = ({ open, onClose, groupInfo }) => {
  const { groupIdx, setGroupIdx, refreshGroupInfo, planName } = useGroup();  // groupIdx: コレクションのインデックス
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);  // selectedGroupId: 選択されたコレクションのID
  const { getAccessTokenSilently } = useAuth0();
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });
  const [loading, setLoading] = useState(false);
  const isMenuOpen = Boolean(menuAnchorEl);
  const [renameGroupDialogOpen, setRenameGroupDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);


  const handleSelectGroup = (index: number) => {
    setGroupIdx(index);
    console.log('handleSelectGroup, index:', index)
    onClose();
  };

  const getGroupIdxFromId = (groupId: number) => {
    const idx = groupInfo.findIndex((group) => group.id === groupId);
    return idx; // 見つからない場合は-1を返す
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, groupId: number) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedGroupId(groupId);
    console.log('handleMenuOpen, groupId:', groupId)
  };

  const handleMenuClose = () => {
    console.log('handleMenuClose')
    setMenuAnchorEl(null);
    setSelectedGroupId(null);
  };

  const handleRenameGroup = () => {
    if (selectedGroupId) {
      setRenameGroupDialogOpen(true);
    }
  };

  // ダイアログを表示
  const handleDeleteClick = () => {
    if (groupInfo.length > 1 && selectedGroupId !== null) {
      console.log("selectedGroupId", selectedGroupId);
      console.log("planName", planName);
      if (planName === "free") {
        setDeleteConfirmDialogOpen(true);
      } else {
        setSnackbarState({
          open: true,
          message: "サブスクリプションをキャンセルしてから削除してください",
          color: "red",
          success: false,
        });
      }
    } else {
      setSnackbarState({
        open: true,
        message: 'コレクションを削除できません。コレクションは1つ以上必要です。',
        color: 'red',
        success: false,
      });
    }
  };

  const handleConfirmDelete = async () => {
    setDeleteConfirmDialogOpen(false);
    try {
      await handleDeleteGroup(); // Wait for the delete operation to complete
  } catch (error) {
      console.error('Failed to delete group:', error);
      // Optionally, show an error message to the user
      setSnackbarState({
          open: true,
          message: 'Failed to delete the group. Please try again.',
          color: 'red',
          success: false,
      });
  } finally {
      handleMenuClose(); // Always close the menu, even if there’s an error
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmDialogOpen(false);
  };


  const handleDeleteGroup = async () => {
    console.log(`Delete group with ID: ${selectedGroupId}`);

    const mutation = `
      mutation DeleteGroup($groupId: Int!) {
        deleteGroup(groupId: $groupId)
      }
    `;

    const variables = { groupId: selectedGroupId };
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      // Make API call
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle response
      //setSnackbarMessage(result.data.deleteGroup);
      refreshGroupInfo();
      if( selectedGroupId && getGroupIdxFromId(selectedGroupId) == groupIdx) {
        setGroupIdx(0);  // If the deleted group is the current group, set the group index to 0
      }
    } catch (error) {
      console.error('Failed to delete group:', error);
    } finally {
      setLoading(false);
      handleMenuClose();
    }

  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const handleRenameGroupConfirm = async (newName: string) => {
    console.log(`Renaming group to: ${newName}`);

    const mutation = `
      mutation UpdateGroupName($groupId: Int!, $newName: String!) {
        updateGroupName(groupId: $groupId, newName: $newName)
      }
    `;

    const variables = { groupId: selectedGroupId, newName: newName };

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Handle response
      //setSnackbarMessage(result.data.deleteGroup);
      refreshGroupInfo();
    } catch (error) {
      console.error('Failed to rename group:', error);
    } finally {
      setLoading(false);
      handleMenuClose();
    }
  };

  const handleCreateCollection = async () => {
    // Check if this user already has a free collection with admin role
    const freeGroups = groupInfo.filter((group) => group.planName === 'free' && group.role === 'admin');
    console.log('Number of freeGroup', freeGroups.length)
    if (freeGroups.length > 0) {
      setSnackbarState({
        open: true,
        message: 'フリープランのコレクションは１つ以上保持できません。',
        color: 'red',
        success: false,
      });
      return;
    }

    const mutation = `
    mutation {
      createGroup {
        id
        groupName
      }
    }`;

    setLoading(true); // Assuming `setLoading` is defined

    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation },
        {
          headers: {
            "Authorization": `Bearer ${token}`, // Replace with your auth token
            "Content-Type": "application/json",
          }
        }
      );

      const collection = result.data.data.createCollection;
      console.log('Collection created:', collection);
      refreshGroupInfo();
      // setSnackbarState({
      //   open: true,
      //   message: 'コレクションの作成に成功しました',
      //   color: 'green',
      //   success: true,
      // });
    } catch (error) {
      console.error('Failed to create collection:', error);
      setSnackbarState({
        open: true,
        message: 'コレクションの作成に失敗しました',
        color: 'red',
        success: false,
      });
    }
    setLoading(false);
  };

  return (
    <Box>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
        sx={{
          position: 'fixed',
          top: '68px',
          right: '40px',
        }}
      >
        {/* Loading Spinner */}
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
        <Box sx={{
          p: '24px',
          minWidth: 300,
          backgroundColor: '#27272C',
          color: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px'
        }}>
          <Typography sx={{
            color: '#747483',
            fontSize: '14px',
          }}>
            コレクションを選択する
          </Typography>
          <List sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
          }}>
            {groupInfo.map((group, index) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <ListItem
                  key={group.id}
                  sx={{
                    cursor: "pointer",
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingY: '8px',
                    gap: '8px',
                    "&:hover": {
                      borderRadius: "var(--borderRadius, 4px)",
                      background: "rgba(255, 255, 255, 0.04)",
                    },
                    "&.Mui-selected, &.Mui-selected:hover": {
                      borderRadius: "var(--borderRadius, 4px)",
                      background: "rgba(255, 255, 255, 0.10)",
                    },
                  }}
                  onClick={() => handleSelectGroup(index)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: 'center',
                      width: "100%",
                    }}
                  >
                    <CollectionRoleChip role={group.role} />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <Typography sx={{ color: '#FFF', fontSize: '14px', fontWeight: 700, pl: '8px' }}>
                      {group.groupName || "Unnamed Group"}
                    </Typography>
                    {group.role != "viewer" && (<IconButton
                      edge="end"
                      aria-label="menu"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleMenuOpen(e, group.id)
                      }}
                      sx={{ color: '#FFF', padding: 0 }}
                    >
                      <MoreVertIcon />
                    </IconButton>)}
                  </Box>
                </ListItem>
                <Divider sx={{ backgroundColor: "#46464F" }} />
              </Box>
            ))}
          </List>
          <Button
            fullWidth
            startIcon={<AddIcon />}
            variant="text"
            onClick={handleCreateCollection}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              color: "#FFF",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }}
          >
            コレクションを追加する
          </Button>
          {/* 名前変更/削除ボタンを含むサブメニュー */}
          <CollectionSubMenu
            menuAnchorEl={menuAnchorEl}
            isMenuOpen={isMenuOpen}
            handleMenuClose={handleMenuClose}
            handleRenameGroup={handleRenameGroup}
            handleDeleteClick={handleDeleteClick}
            role={selectedGroupId ? groupInfo[getGroupIdxFromId(selectedGroupId)].role : "admin"}
            setSnackbarState={setSnackbarState}
          />
          {/* コレクションの名前を変更するダイアログ */}
          <RenameGroupDialog
            open={renameGroupDialogOpen}
            onClose={() => setRenameGroupDialogOpen(false)}
            currentGroupName={groupInfo.find((group) => group.id === selectedGroupId)?.groupName || '新規コレクション'}
            onConfirm={handleRenameGroupConfirm}
          />

          {/* 削除確認ダイアログ */}
          <DeleteCollectionDialog
            open={deleteConfirmDialogOpen}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />
        </Box>
      </Popover >
      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />
    </Box>
  );
};

export default CollectionsInfoDisplay;
