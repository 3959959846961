import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  InputAdornment
} from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from '../../GroupContext';
import { WebsiteType, SnackbarType, NewsType } from '../../types/types';
import NotificationSnackbar from '../shared/NotificationSnackbar';
import SortOrderButton, { SortOrder } from './WebsiteSortOrderButton';
import NewsItemsModal from '../shared/NewsItemsModal';
import { ReactComponent as SearchIcon } from '../../assets/icons/SearchIcon.svg'
import AddWebsitesPanel from './AddWebsitesPanel';
import GroupWebsitesList from './GroupWebsitesList';
import { ToggleSwitch } from '../shared/ToggleSwitch';

const WebsitesPanel: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { groupIdx, groupInfo, groupId, currentRole } = useGroup();
  const [groupWebsites, setGroupWebsites] = useState<WebsiteType[]>([]);
  const [unassociatedWebsites, setUnassociatedWebsites] = useState<WebsiteType[]>([]); // New state for enabled websites not in group
  const [newsCounts, setNewsCounts] = useState<Record<number, number>>({});
  const [loading, setLoading] = useState(false);
  const [newWebsiteUrl, setNewWebsiteUrl] = useState('');
  const [addingWebsite, setAddingWebsite] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });
  const [sortOrderForAdded, setSortOrderForAdded] = useState<SortOrder>('count')
  const [sortOrderForUnassociated, setSortOrderForUnassociated] = useState<SortOrder>('count')
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showUrls, setShowUrls] = useState<boolean>(false);
  const [showUnassociatedUrls, setShowUnassociatedUrls] = useState<boolean>(false);
  const [isAddPanelOpen, setIsAddPanelOpen] = useState<boolean>(false);
  const [selectedUnAssociatedWebsites, setSelectedUnAssociatedWebsites] = useState<number[]>([]);
  const [selectedWebsites, setSelectedWebsites] = useState<number[]>([]);
  const [selectedNews, setSelectedNews] = useState<NewsType[]>([]);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [selectedWebsiteTitle, setSelectedWebsiteTitle] = useState<string>('');
  const [selectMode, setSelectMode] = useState(false);

  const [websitesLimit, setWebsitesLimit] = useState<number>(parseInt(process.env.REACT_APP_LIMIT_WEBSITES_FREE_PLAN || '3', 10));
  const [isOverWebsitesLimit, setIsOverWebsitesLimit] = useState<boolean>(false);

  const toggleSelectMode = () => {
    setSelectMode(!selectMode)
    // 選択している状態は引き継がない
    unselectWebsites()
  };

  // キーワードの選択を全て解除する
  const unselectWebsites = () => {
    setSelectedWebsites([])
  };

  const getWebsitesLimitByPlan = (planName: string | null): number => {
    const limits: Record<string, number> = {
      free: parseInt(process.env.REACT_APP_LIMIT_WEBSITES_FREE_PLAN || '3', 10),
      small: parseInt(process.env.REACT_APP_LIMIT_WEBSITES_SMALL_PLAN || '10', 10),
      large: parseInt(process.env.REACT_APP_LIMIT_WEBSITES_LARGE_PLAN || '20', 10),
      business: parseInt(process.env.REACT_APP_LIMIT_WEBSITES_BUSINESS_PLAN || '30', 10),
      business_plus: parseInt(process.env.REACT_APP_LIMIT_WEBSITES_BUSINESS_PLUS_PLAN || '60', 10),
    };
    return limits[planName?.toLowerCase() || 'free'] || 1; // 該当するプランがなければ0を返す
  };

  // Fetch group-specific websites
  const fetchWebsitesByGroup = async ({ showSnackbar = true }: { showSnackbar?: boolean } = {}) => {
    console.log('fetchWebsitesByGroup')
    const query = `
      query GetWebsitesByGroup($groupId: Int!) {
        websitesByGroup(groupId: $groupId) {
          id
          name
          url
          title
          status
          rss
        }
      }
    `;
    const variables = { groupId: groupId };

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setGroupWebsites(result.data.data.websitesByGroup);
    } catch (error) {
      console.error('Failed to fetch group websites:', error);
      setSnackbarState({ open: true, message: 'Webサイトの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const fetchNewsCounts = async () => {
    const query = `
      query GetNewsCountByWebsite($groupId: Int!) {
        newsCountByWebsite(groupId: $groupId) {
          websiteId
          newsCount
        }
      }
    `;
    const variables = { groupId: groupId };

    try {
      const accessToken = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const counts = result.data.data.newsCountByWebsite;
      const countsMap = counts.reduce((acc: Record<number, number>, curr: { websiteId: number; newsCount: number }) => {
        acc[curr.websiteId] = curr.newsCount;
        return acc;
      }, {});
      setNewsCounts(countsMap);
    } catch (error) {
      console.error('Failed to fetch news counts:', error);
    }
  };

  // Fetch enabled websites not in group
  const fetchUnassociatedWebsites = async () => {
    const query = `
      query GetUnassociatedWebsites($groupId: Int!) {
        unassociatedWebsites(groupId: $groupId) {
          id
          name
          url
          title
          status
          rss
        }
      }
    `;
    const variables = { groupId: groupId };
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setUnassociatedWebsites(result.data.data.unassociatedWebsites);
    } catch (error) {
      console.error('Failed to fetch unassociated enabled websites:', error);
      setSnackbarState({ open: true, message: '未登録のWebサイトの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const requestNewWebsite = async () => {
    if (!newWebsiteUrl) return;

    const websitesLimit = getWebsitesLimitByPlan(groupInfo[groupIdx].planName || 'free');
    if (groupWebsites.length >= websitesLimit) {
      setSnackbarState({
        open: true,
        message: `Webサイトの上限（${websitesLimit}個）に達しています。Webサイトの数を減らしてください。`,
        color: 'red',
        success: false,
      });
      return;
    }

    // Check if url is in the list of associated websites
    if (groupWebsites.some(website => {
      const sanitizedWebsiteUrl = website.url.endsWith('/') ? website.url.slice(0, -1) : website.url;
      const sanitizedNewWebsiteUrl = newWebsiteUrl.endsWith('/') ? newWebsiteUrl.slice(0, -1) : newWebsiteUrl;
      return sanitizedWebsiteUrl === sanitizedNewWebsiteUrl;
    })) {
      setSnackbarState({ open: true, message: 'このWebサイトは既に登録済みです。', color: 'red', success: false });
      return;
    }

    setAddingWebsite(true);
    setSnackbarState({ open: false, message: '', color: 'blue', success: true });

    const urlToAdd = newWebsiteUrl;

    const mutation = `
      mutation RequestWebsite($groupId: Int!, $url: String!) {
        requestWebsite(groupId: $groupId, url: $url) {
          id
          name
          url
          title
          status
          rss
        }
      }
    `;
    const variables = { groupId: groupId, url: urlToAdd };

    try {
      const accessToken = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      const addedWebsite = result.data.data.requestWebsite;
      setGroupWebsites((prev) => [...prev, addedWebsite]);
      setNewWebsiteUrl('');
      setSnackbarState({ open: true, message: 'Webサイトの申請に成功しました。', color: 'green', success: true });
      fetchUnassociatedWebsites();
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors?.[0] || error.message;
      if (errorMessage.includes('Website limit')) {
        const match = errorMessage.match(/Website limit of (\d+)/);
        const limit = match ? match[1] : '不明な';
        setSnackbarState({
          open: true,
          message: `Webサイトの上限（${limit}個）に達しています。Webサイトの数を減らしてください。`,
          color: 'red',
          success: false,
        });
      } else {
        setSnackbarState({ open: true, message: 'Webサイトの申請に失敗しました。', color: 'red', success: false });
      }
    } finally {
      setAddingWebsite(false);
    }
  };

  const removeWebsitesFromGroup = async (websiteIds: number[]) => {
    const mutation = `
      mutation RemoveWebsitesFromGroup($groupId: Int!, $websiteIds: [Int!]!) {
        removeWebsitesFromGroup(groupId: $groupId, websiteIds: $websiteIds) {
          message
          removedWebsiteIds
          scheduledForDeletionIds
        }
      }
    `;
    const variables = { groupId: groupId, websiteIds: websiteIds };

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation, variables },
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          }
        }
      );

      await fetchWebsitesByGroup({ showSnackbar: false });
      await fetchUnassociatedWebsites();
      unselectWebsites();
      setSnackbarState({ open: true, message: 'Webサイトをコレクションから削除しました。', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to remove websites:', error);
      setSnackbarState({ open: true, message: 'Webサイトの削除に失敗しました。', color: 'red', success: false });
    } finally {
      setWebsitesLimit(getWebsitesLimitByPlan(groupInfo[groupIdx].planName || 'free'));
      if (groupWebsites.length <= websitesLimit) {
        setIsOverWebsitesLimit(false);
      }
      setLoading(false);
    }
  };


  const addWebsitesToGroup = async (websiteIds: number[]) => {
    const websitesLimit = getWebsitesLimitByPlan(groupInfo[groupIdx].planName || 'free');
    const newTotalWebsites = groupWebsites.length + websiteIds.length;
    if (newTotalWebsites > websitesLimit) {
      setSnackbarState({
        open: true,
        message: `Webサイトの上限（${websitesLimit}個）に達しています。Webサイトの数を減らしてください。`,
        color: 'red',
        success: false,
      });
      return;
    }

    const mutation = `
      mutation AddWebsitesToGroup($groupId: Int!, $websiteIds: [Int!]!) {
        addWebsitesToGroup(groupId: $groupId, websiteIds: $websiteIds)
      }
    `;
    const variables = { groupId: groupId, websiteIds: websiteIds };

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setSnackbarState({
        open: true,
        message: 'Webサイトをコレクションに追加しました',
        color: 'blue',
        success: true
      });
      await fetchWebsitesByGroup({ showSnackbar: false });
      await fetchUnassociatedWebsites();
    } catch (error: any) {
      console.error('Failed to add website to group:', error);
      // エラーがウェブサイト制限に関連する場合の処理
      const errorMessage = error.response?.data?.errors?.[0] || error.message;
      if (errorMessage.includes('Website limit')) {
        const match = errorMessage.match(/Website limit of (\d+)/);
        const limit = match ? match[1] : '不明な';
        setSnackbarState({
          open: true,
          message: `Webサイトの上限（${limit}個）に達しています。Webサイトの数を減らしてください。`,
          color: 'red',
          success: false,
        });
      } else {
        setSnackbarState({
          open: true,
          message: 'Webサイトの追加に失敗しました。',
          color: 'red',
          success: false
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNewsByWebsiteId = async (websiteId: number, websiteTitle: string) => {
    const query = `
    query GetNewsByWebsiteAndGroup($groupId: Int!, $websiteId: Int!) {
      newsByWebsiteId(groupId: $groupId, websiteId: $websiteId) {
        id
        title
        url
        summary
        releasedTimestamp
        keywords
        websiteTitle
      }
    }
    `;
    const variables = { groupId: groupId, websiteId: websiteId };

    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setSelectedNews(result.data.data.newsByWebsiteId);
      setSelectedWebsiteTitle(websiteTitle);
      setNewsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch news by website ID:', error);
      setSnackbarState({ open: true, message: 'ニュースの取得に失敗しました', color: 'red', success: false });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const handleNewsItemsModalClose = () => {
    setNewsModalOpen(false);
  };

  useEffect(() => {
    if (groupId) {
      fetchWebsitesByGroup();
      fetchUnassociatedWebsites();
      fetchNewsCounts();
    }
  }, [groupId]);

  const handleUnAssociatedWebsiteSelection = (websiteId: number) => {
    setSelectedUnAssociatedWebsites((prev) =>
      prev.includes(websiteId)
        ? prev.filter((id) => id !== websiteId) // 既に選択済みなら削除
        : [...prev, websiteId] // 未選択なら追加
    );
  }

  const handleWebsiteSelection = (websiteId: number) => {
    setSelectedWebsites((prev) =>
      prev.includes(websiteId)
        ? prev.filter((id) => id !== websiteId) // 既に選択済みなら削除
        : [...prev, websiteId] // 未選択なら追加
    );
  }

  const sortedWebsites = [...groupWebsites].sort((a, b) => {
    if (sortOrderForAdded === 'count') {
      return (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0);
    } else if (sortOrderForAdded === 'title') {
      return a.title.localeCompare(b.title);
    } else {
      return a.id - b.id; // added順
    }
  });

  const displayedWebsites = sortedWebsites.filter(
    (website) =>
      website.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      website.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
      website.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // 新規ウェブサイト追加パネル上での検索
  const filteredUnassociatedWebsites = unassociatedWebsites.filter(
    (website) =>
      website.name.toLowerCase().includes(newWebsiteUrl.toLowerCase()) ||
      website.url.toLowerCase().includes(newWebsiteUrl.toLowerCase()) ||
      website.title.toLowerCase().includes(newWebsiteUrl.toLowerCase())
  );

  const sortedUnassociatedWebsites = [...filteredUnassociatedWebsites].sort((a, b) => {
    if (sortOrderForUnassociated === 'count') {
      return (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0);
    } else if (sortOrderForUnassociated === 'title') {
      return a.title.localeCompare(b.title);
    } else {
      return a.id - b.id; // added順
    }
  });

  useEffect(() => {
    if (!groupInfo[groupIdx]) {
      // groupInfoがまだ取得できていない場合はreturnして処理しない
      return;
    }

    const newLimit = getWebsitesLimitByPlan(groupInfo[groupIdx].planName);
    setWebsitesLimit(newLimit);
    if (groupWebsites.length > websitesLimit) {
      setSelectMode(true);
      setIsOverWebsitesLimit(true);
    } else {
      setIsOverWebsitesLimit(false);
    }
  }, [groupInfo[groupIdx], groupWebsites]);

  useEffect(() => {
    if (isOverWebsitesLimit) {
      setSnackbarState({
        open: true,
        message: `Webサイトの上限（${websitesLimit}個）に達しています。Webサイトの数を減らしてください。`,
        color: 'red',
        success: false
      });
    }
  }, [isOverWebsitesLimit]);

  return (
    <Box
      sx={{
        padding: 5,
        backgroundColor: '#FFF',
        borderRadius: 'var(--1, 8px)',
        flexGrow: 1,
        minWidth: '100%',
        boxSizing: 'border-box',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      {isOverWebsitesLimit && (
        <Box>
          <Typography
            sx={{
              color: '#FF0000'
            }}
          >
            ウェブサイトの上限は{websitesLimit}個です。削除してください。
          </Typography>
        </Box>
      )}
      {/* Webサイト管理画面の本体 */}
      <Box mb={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 4, height: '100%' }}>
        {/* タイトル含め最上部のコンテナ */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography sx={{
            color: '#17171A',
            fontSize: '24px',
            fontWeight: 700,
            flex: '1 0 0'
          }}>
            Webサイト
          </Typography>

          {/* 新規追加パネルのボタン 上限未満の場合のみ表示 */}
          {currentRole !== 'viewer' && (
            <Box>
              <Button
                onClick={() => setIsAddPanelOpen(true)}
                disabled={addingWebsite}
                sx={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  background: '#DCDCE0',
                  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)',
                  gap: '4px',
                  color: '#46464F',
                  fontSize: '13px'
                }}
              >
                {addingWebsite ? '追加中...' : '+ 新規追加'}
              </Button>
              <AddWebsitesPanel
                isOpen={isAddPanelOpen}
                setIsOpen={setIsAddPanelOpen}
                newWebsiteUrl={newWebsiteUrl}
                setNewWebsiteUrl={setNewWebsiteUrl}
                requestNewWebsite={requestNewWebsite}
                sortOrderForUnassociated={sortOrderForUnassociated}
                setSortOrderForUnassociated={setSortOrderForUnassociated}
                sortedUnassociatedWebsites={sortedUnassociatedWebsites}
                selectedUnAssociatedWebsites={selectedUnAssociatedWebsites}
                handleUnAssociatedWebsiteSelection={handleUnAssociatedWebsiteSelection}
                fetchNewsByWebsiteId={fetchNewsByWebsiteId}
                newsCounts={newsCounts}
                showUrls={showUnassociatedUrls}
                setShowUrls={setShowUnassociatedUrls}
                setSelectedUnAssociatedWebsites={setSelectedUnAssociatedWebsites}
                addWebsitesToGroup={addWebsitesToGroup}
              />
            </Box>
          )}
        </Box>
        {/* 最上部以外、本体のコンテナ */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '1080px', height: '100%', overflow: 'auto' }}>
          {/* Input and Button for Requesting a New Website */}
          <Typography variant="h6" sx={{ mt: 0, color: '#27272C', fontSize: '16px' }}>登録したWebサイト</Typography>
          {/* 検索窓とオプション */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, justifyContent: 'space-between', alignSelf: 'stretch' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
              {/* 検索窓 */}
              <TextField
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="キーワードかURLを入力"
                variant="outlined"
                sx={{
                  width: '300px',
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0 }}>
                {/* URLトグル */}
                <Typography mr='-4px' sx={{ color: '#7A7A7A', fontFamily: 'Roboto' }}>url</Typography>
                <ToggleSwitch
                  checked={showUrls}
                  onChange={(e) => setShowUrls(e.target.checked)}
                />
              </Box>

              {/* 並び順ボタン */}
              <SortOrderButton sortOrder={sortOrderForAdded} setSortOrder={setSortOrderForAdded} />
            </Box>
            {/* 選択ボタンたち */}
            <Box>
              {selectMode && (
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  {!isOverWebsitesLimit && (
                    <Button
                      onClick={toggleSelectMode}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#FFF',
                        padding: '4px 16px',
                        transition: 'background 0.3s ease',
                        '&:hover': {
                          background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.07) 0%, #FFFFFF 100%)',
                        },
                        '&:active': {
                          background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.15) 0%, #FFFFFF 100%)',
                        },
                      }}
                      disableRipple
                    >
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: '#46464F',
                        }}
                      >
                        キャンセル
                      </Typography>
                    </Button>
                  )}
                  <Button
                    onClick={unselectWebsites}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#FFF',
                      borderRadius: '4px',
                      border: '1px solid #CBCBD0',
                      padding: '4px 16px',
                      cursor: 'pointer',
                      transition: 'background 0.3s ease, border-color 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.07) 0%, #FFFFFF 100%)',
                        border: '1px solid #CBCBD0',
                      },
                      '&:active': {
                        background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.15) 0%, #FFFFFF 100%)',
                        border: '1px solid #656572',
                      },
                    }}
                    disableRipple
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        color: '#656572',
                      }}
                    >
                      選択解除
                    </Typography>
                  </Button>

                  <Button
                    onClick={() => removeWebsitesFromGroup(selectedWebsites)}
                    disableRipple
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#FFF',
                      borderRadius: '4px',
                      border: '1px solid rgba(183, 39, 46, 0.5)',
                      padding: '4px 16px',
                      '&:hover': {
                        background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // ホバー時の背景
                        border: '1px solid rgba(183, 39, 46, 0.5)',
                      },
                      '&:active': {
                        background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // クリック時の背景
                        border: '1px solid #B7272E',
                      },
                      transition: 'background 0.3s ease, border-color 0.3s ease',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        color: '#B7272E',
                      }}
                    >
                      一括削除
                    </Typography>
                  </Button>
                </Box>
              )}
              {/* 選択モードにするボタン */}
              {!selectMode && currentRole !== 'viewer' && (
                <Button
                  onClick={toggleSelectMode}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#DCDCE0',
                    borderRadius: '4px',
                    padding: '4px 16px',
                    transition: 'background 0.3s ease',
                    '&:hover': {
                      background: 'linear-gradient(180deg, rgba(220, 220, 224, 0.7) 0%, #DCDCE0 100%)',
                    },
                    '&:active': {
                      background: 'linear-gradient(180deg, rgba(220, 220, 224, 0.85) 0%, #DCDCE0 100%)',
                    },
                  }}
                  disableRipple
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#46464F',
                    }}
                  >
                    選択する
                  </Typography>
                </Button>

              )}
            </Box>
          </Box>


          {/* List of Group Websites */}
          <GroupWebsitesList
            displayedWebsites={displayedWebsites}
            selectMode={selectMode}
            selectedWebsites={selectedWebsites}
            handleWebsiteSelection={handleWebsiteSelection}
            fetchNewsByWebsiteId={fetchNewsByWebsiteId}
            newsCounts={newsCounts}
            showUrls={showUrls}
          />
        </Box>
      </Box>

      <NewsItemsModal
        open={newsModalOpen}
        onClose={handleNewsItemsModalClose}
        newsItems={selectedNews}
        modalTitle={selectedWebsiteTitle}
      />

      {/* Loading Spinner */}
      <Backdrop open={loading || addingWebsite} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />
    </Box>
  );
};

export default WebsitesPanel;
