import React, { useState } from 'react';
import { Button, Typography, Menu, MenuItem, Box } from '@mui/material';
import { ReactComponent as DownArrowFilled } from '../../assets/icons/DownArrowFilled.svg';

// 型定義
export type SortOrder = 'role' | 'name' | 'email' | 'emailEnabled';

interface SortOrderButtonProps {
  sortOrder: SortOrder;
  setSortOrder: (order: SortOrder) => void;
}

const SortOrderButton: React.FC<SortOrderButtonProps> = ({ sortOrder, setSortOrder }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (order: SortOrder) => {
    setSortOrder(order);
    handleClose();
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'none',
          border: 'none',
          borderBottom: '1px solid #CBCBD0',
          borderRadius: '0px',
          padding: 0,
          gap: '4px',
          opacity: '0.6',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            lineHeight: '140%',
            marginBottom: '4.5px',
          }}
        >
          {sortOrder === 'role' ? '権限順' :
            sortOrder === 'name' ? '名前順' :
            sortOrder === 'email' ? 'メール順' : 'メール配信有効'}
        </Typography>
        <DownArrowFilled style={{ marginBottom: '4.5px' }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <MenuItem onClick={() => handleSelect('role')}>権限順</MenuItem>
        <MenuItem onClick={() => handleSelect('name')}>名前順</MenuItem>
        <MenuItem onClick={() => handleSelect('email')}>メールアドレス順</MenuItem>
        <MenuItem onClick={() => handleSelect('emailEnabled')}>配信有効</MenuItem>
      </Menu>
    </Box>
  );
};

export default SortOrderButton;
