import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Backdrop,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from '../../GroupContext';
import { KeywordType, SnackbarType, NewsType } from '../../types/types';
import { ReactComponent as SearchIcon } from '../../assets/icons/SearchIcon.svg';
import NotificationSnackbar from '../shared/NotificationSnackbar';
import KeywordSortOrderButton from './KeywordSortOrderButton';
import KeywordChipList from './KeywordChips';
import NewsItemsModal from '../shared/NewsItemsModal';
import { RoleType } from '../../types/types';

const KeywordsPanel: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { groupId, currentRole, planName } = useGroup();
  const [groupKeywords, setGroupKeywords] = useState<KeywordType[]>([]);
  const [newlyAddedKeywordIds, setNewlyAddedKeywordIds] = useState<Set<number>>(new Set()); // 最近追加されたキーワードのIDを管理

  const [newsCounts, setNewsCounts] = useState<Record<number, number>>({});
  const [loading, setLoading] = useState(false);
  const [newKeyword, setNewKeyword] = useState('');
  const [addingKeyword, setAddingKeyword] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });

  const [selectMode, setSelectMode] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState<number[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // キーワードフィルタリング関連
  const [searchKeyword, setSearchKeyword] = useState('');
  const [sortOrder, setSortOrder] = useState<'added' | 'keyword' | 'count'>('added');
  const [filteredKeywords, setFilteredKeywords] = useState(groupKeywords);

  const [selectedNews, setSelectedNews] = useState<NewsType[]>([]);
  const [newsModalOpen, setNewsModalOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');
  // const [currentRole, setCurrentRole] = useState<RoleType>();

  const [keywordsLimit, setKeywordsLimit] = useState<number>(parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_FREE_PLAN || '3', 10));
  const [isOverKeywordsLimit, setIsOverKeywordsLimit] = useState<boolean>(false);  // キーワードの上限を超えているかどうか(true: 超えている)

  // useEffect(() => {
  //   if (groupInfo && groupInfo.length > groupIdx && groupInfo[groupIdx]) {
  //     // setCurrentRole(groupInfo[groupIdx].role);
  //     console.log("I am " + groupInfo[groupIdx].role);
  //   } else {
  //     console.log("groupInfo is not loaded yet or groupIdx is out of bounds");
  //   }
  // }, [groupIdx, groupInfo]);


  const toggleSelectMode = () => {
    setSelectMode(!selectMode)
    // 選択している状態は引き継がない
    unselectKeywords()
  };

  // キーワードの選択を全て解除する
  const unselectKeywords = () => {
    setSelectedKeywords([])
  }

  // キーワードの並び順を変更する
  const toggleSortOrder = () => {
    const nextSortOrder =
      sortOrder === 'added' ? 'count' : sortOrder === 'count' ? 'keyword' : 'added';
    setSortOrder(nextSortOrder);

    // 並び替えを適用
    sortKeywords(filteredKeywords, nextSortOrder);
  };

  // キーワードを並び替える
  const sortKeywords = (keywords: KeywordType[], order: string) => {
    const sortedKeywords = [...keywords];
    if (order === 'count') {
      sortedKeywords.sort((a, b) => (newsCounts[b.id] || 0) - (newsCounts[a.id] || 0));
    } else if (order === 'keyword') {
      sortedKeywords.sort((a, b) => a.keyword.localeCompare(b.keyword));
    } else {
      sortedKeywords.sort((a, b) => a.id - b.id); // 追加順
    }
    setFilteredKeywords(sortedKeywords);
  };

  // 検索窓の入力変更をハンドリング
  const handleSearchChange = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
    // 入力値に基づいてフィルタリング
    const filtered = groupKeywords.filter((keyword) =>
      keyword.keyword.toLowerCase().includes(searchKeyword.toLowerCase())
    );

    // 現在の並び順を適用して更新
    sortKeywords(filtered, sortOrder);
  };

  const getKeywordsLimitByPlan = (planName: string | null): number => {
    const limits: Record<string, number> = {
      free: parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_FREE_PLAN || '3', 10),
      small: parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_SMALL_PLAN || '10', 10),
      large: parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_LARGE_PLAN || '20', 10),
      business: parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_BUSINESS_PLAN || '30', 10),
      business_plus: parseInt(process.env.REACT_APP_LIMIT_KEYWORDS_BUSINESS_PLUS_PLAN || '60', 10),
    };
    return limits[planName?.toLowerCase() || 'free'] || 1; // 該当するプランがなければ0を返す
  };

  // キーワード一覧か並び順に変化があった場合、フィルタを再適用
  useEffect(() => {
    sortKeywords(groupKeywords, sortOrder);
    handleSearchChange(searchKeyword);
  }, [groupKeywords, sortOrder]);

  const fetchKeywordsByGroup = async () => {
    const query = `
      query GetKeywordsByGroup($groupId: Int!){
        keywordsByGroup(groupId: $groupId ) {
          id
          keyword
        }
      }
    `;
    const variables = { groupId: groupId };
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      const keywords = result.data.data.keywordsByGroup
      const sortedKeywords = [...keywords]
      sortedKeywords.sort((a, b) => a.id - b.id); // 追加順にソートしておく
      setGroupKeywords(sortedKeywords);
      handleSearchChange(searchKeyword);
    } catch (error) {
      console.error('Failed to fetch user group keywords:', error);
      setSnackbarState({ open: true, message: 'キーワードの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const fetchNewsCounts = async () => {
    const query = `
      query GetNewsCountByKeyword($groupId: Int!) {
        newsCountByKeyword(groupId: $groupId) {
          keywordId
          newsCount
        }
      }
    `;

    const variables = { groupId: groupId };

    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const counts = result.data.data.newsCountByKeyword;
      const countsMap = counts.reduce((acc: Record<number, number>, item: { keywordId: number, newsCount: number }) => {
        acc[item.keywordId] = item.newsCount;
        return acc;
      }, {});
      setNewsCounts(countsMap); // Save news counts for each keyword
    } catch (error) {
      console.error('Failed to fetch news counts by keyword:', error);
      setSnackbarState({ open: true, message: 'ニュース数の取得に失敗しました', color: 'red', success: false });
    }
  };

  const addNewKeyword = async () => {
    if (!newKeyword) return;

    const halfWidthToFullWidthKana = (str: string): string => {
      const kanaMap: Record<string, string> = {
        ｱ: 'ア', ｲ: 'イ', ｳ: 'ウ', ｴ: 'エ', ｵ: 'オ',
        ｶ: 'カ', ｷ: 'キ', ｸ: 'ク', ｹ: 'ケ', ｺ: 'コ',
        ｻ: 'サ', ｼ: 'シ', ｽ: 'ス', ｾ: 'セ', ｿ: 'ソ',
        ﾀ: 'タ', ﾁ: 'チ', ﾂ: 'ツ', ﾃ: 'テ', ﾄ: 'ト',
        ﾅ: 'ナ', ﾆ: 'ニ', ﾇ: 'ヌ', ﾈ: 'ネ', ﾉ: 'ノ',
        ﾊ: 'ハ', ﾋ: 'ヒ', ﾌ: 'フ', ﾍ: 'ヘ', ﾎ: 'ホ',
        ﾏ: 'マ', ﾐ: 'ミ', ﾑ: 'ム', ﾒ: 'メ', ﾓ: 'モ',
        ﾔ: 'ヤ', ﾕ: 'ユ', ﾖ: 'ヨ',
        ﾗ: 'ラ', ﾘ: 'リ', ﾙ: 'ル', ﾚ: 'レ', ﾛ: 'ロ',
        ﾜ: 'ワ', ｦ: 'ヲ', ﾝ: 'ン',
        ｧ: 'ァ', ｨ: 'ィ', ｩ: 'ゥ', ｪ: 'ェ', ｫ: 'ォ',
        ｬ: 'ャ', ｭ: 'ュ', ｮ: 'ョ', ｯ: 'ッ',
        ﾞ: '゛', ﾟ: '゜'
      };

      return str.replace(/[\uFF61-\uFF9F]/g, (char) => kanaMap[char] || char);
    };

    // キーワード整形処理（制約に従う）
    const normalizeKeyword = (keyword: string): string => {
      return keyword
        .trim() // 前後のスペースを削除
        .replace(/\s+/g, ' ') // 中間のスペースを1つに置き換え
        .toUpperCase() // アルファベットを大文字に変換
        .replace(/[ｧ-ﾝﾞﾟ]/g, halfWidthToFullWidthKana) // 半角カタカナを全角に変換
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (char) => String.fromCharCode(char.charCodeAt(0) - 0xFEE0)); // 全角アルファベット・数字を半角に変換
    };

    const normalizedKeyword = normalizeKeyword(newKeyword);

    // 整形されたキーワードがすでに登録済みか確認
    const isKeywordAlreadyRegistered = groupKeywords.some(
      (keyword) => keyword.keyword === normalizedKeyword
    );

    if (isKeywordAlreadyRegistered) {
      setSnackbarState({
        open: true,
        message: 'キーワードはすでに登録済みです。',
        color: 'red',
        success: false,
      });
      return;
    }

    if (groupKeywords.length > keywordsLimit) {
      // フロントエンドで制限数を超える場合
      setSnackbarState({
        open: true,
        message: `キーワードの上限（${keywordsLimit}個）に達しました。`,
        color: 'red',
        success: false,
      });
      return;
    }

    setAddingKeyword(true);

    const mutation = `
      mutation AddKeywordToGroup($groupId: Int!, $keyword: String!) {
        addKeywordToGroup(groupId: $groupId, keyword: $keyword) {
          id
          keyword
        }
      }
    `;

    const variables = { groupId: groupId, keyword: normalizedKeyword };

    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      const addedKeyword = result.data.data.addKeywordToGroup;
      setGroupKeywords((prev) => [...prev, addedKeyword]);

      // 新しく追加されたキーワードのIDを登録（一定時間後に削除）
      setNewlyAddedKeywordIds((prev) => {
        const newSet = new Set(prev);
        newSet.add(addedKeyword.id);
        setTimeout(() => {
          setNewlyAddedKeywordIds((current) => {
            const updatedSet = new Set(current);
            updatedSet.delete(addedKeyword.id);
            return updatedSet;
          });
        }, 2000); // 2秒後に削除
        return newSet;
      });

      setNewKeyword('');
      setSnackbarState({ open: true, message: 'キーワードを追加しました', color: 'blue', success: true });
    } catch (error: any) {
      console.log("error: ", error);
      const errorMessage = error.response?.data?.errors?.[0] || error.message;

      if (errorMessage.includes('Keyword limit')) {
        // 制限数エラーの場合のメッセージ
        const match = errorMessage.match(/Keyword limit of (\d+) reached/);
        const limit = match ? match[1] : '不明な';
        setSnackbarState({
          open: true,
          message: `キーワードの上限（${limit}個）に達しました。`,
          color: 'red',
          success: false,
        });
      } else {
        setSnackbarState({
          open: true,
          message: 'キーワードの追加に失敗しました。',
          color: 'red',
          success: false,
        });
      }
    } finally {
      setAddingKeyword(false);
    }
  };

  const removeKeywordsFromGroup = async () => {
    setLoading(true);
    setConfirmOpen(false);
    try {
      const token = await getAccessTokenSilently();
      await Promise.all(
        selectedKeywords.map(async (id) => {
          const mutation = `
            mutation RemoveKeywordFromGroup($groupId: Int!, $keywordId: Int!) {
              removeKeywordFromGroup(groupId: $groupId, keywordId: $keywordId)
            }
          `;
          const variables = { groupId: groupId, keywordId: id };
          await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation, variables }, {
            headers: {
              "Authorization": `Bearer ${token}`,
              'Content-Type': 'application/json',
            }
          });
        })
      );
      setGroupKeywords((prev) => prev.filter((keyword) => !selectedKeywords.includes(keyword.id)));
      setSnackbarState({ open: true, message: '選択したキーワードを削除しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to remove keywords:', error);
      setSnackbarState({ open: true, message: '一括削除に失敗しました', color: 'blue', success: true });
    } finally {
      // キーワードの数が上限を下回った場合はオーバー制限を解除
      setKeywordsLimit(getKeywordsLimitByPlan(planName || 'free'));
      if (groupKeywords.length <= keywordsLimit) {
        setIsOverKeywordsLimit(false);
      }
      setLoading(false);
      setConfirmOpen(false);
      setSelectedKeywords([]);
    }
  };

  const fetchNewsByKeywordId = async (keywordId: number, keywordName: string) => {
    const query = `
    query GetNewsByKeywordId($groupId: Int!, $keywordId: Int!) {
      newsByKeywordId(groupId: $groupId, keywordId: $keywordId) {
        id
        title
        url
        summary
        releasedTimestamp
        keywords
        websiteTitle
      }
      }
    `;
    const variables = { groupId: groupId, keywordId: keywordId };

    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setSelectedNews(result.data.data.newsByKeywordId);
      setSelectedKeyword(keywordName);
      setNewsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch news by keyword:', error);
      setSnackbarState({ open: true, message: 'ニュースの取得に失敗しました', color: 'red', success: false });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxToggle = (id: number) => {
    setSelectedKeywords((prev) =>
      prev.includes(id) ? prev.filter((keywordId) => keywordId !== id) : [...prev, id]
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  const handleNewsItemsModalClose = () => {
    setNewsModalOpen(false);
  };

  useEffect(() => {
    if (groupId) {
      fetchKeywordsByGroup();
      fetchNewsCounts();
    }
  }, [groupId]);

  useEffect(() => {
    console.log("planName: ", planName);
    if (!planName) {
      // planNameがまだ取得できていない場合はreturnして処理しない
      return;
    }

    const newLimit = getKeywordsLimitByPlan(planName);
    console.log("newLimit: ", newLimit);
    setKeywordsLimit(newLimit);
    if (groupKeywords.length > keywordsLimit) {
      setSelectMode(true);
      setIsOverKeywordsLimit(true);
    } else {
      setIsOverKeywordsLimit(false);
    }
  }, [planName, groupKeywords]);

  useEffect(() => {
    if (isOverKeywordsLimit) {
      setSnackbarState({
        open: true,
        message: `キーワードの上限（${keywordsLimit}個）を超えています。削除してください。`,
        color: 'red',
        success: false
      });
    }
  }, [isOverKeywordsLimit]);

  return (
    // 全体を格納するコンテナ
    <Box
      sx={{
        padding: 5,
        backgroundColor: '#FFF',
        borderRadius: 'var(--1, 8px)',
        flexGrow: 1,
        minWidth: '100%',
        boxSizing: 'border-box'
      }}
    >
      {isOverKeywordsLimit && (
        <Box>
          <Typography
            sx={{
              color: '#FF0000'
            }}
          >
            キーワードの上限は{keywordsLimit}個です。削除してください。
          </Typography>
        </Box>
      )}

      {/* ユーザー管理画面の本体 */}
      {/* ページタイトル */}
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%', // 33.6px
          }}
        >
          キーワード
        </Typography>
      </Box>

      {/* キーワード入力コンテナ */}
      {currentRole !== 'viewer' && (<Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: '#27272C',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
            mb: 2,
          }}
        >
          登録したいキーワードを入力
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
          <TextField
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            placeholder="例)生成AI (2文字以上)"
            variant="standard" // アンダーラインスタイルを標準とする
            InputProps={{
              disableUnderline: true, // MUIのデフォルトのアンダーラインを無効化
              sx: {
                height: '46px', // 全体の高さ
                padding: '0 12px', // 横方向の内側余白
                background: newKeyword.trim()
                  ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #EEEEF0' // 入力がある時の背景色
                  : '#EEEEF0', // 空の時の背景色
                borderRadius: 'var(--borderRadius, 4px) var(--borderRadius, 4px) var(--none, 0px) var(--none, 0px)', // 角丸設定
                alignItems: 'center', // 高さ方向で中央揃え
                borderBottom: '1px solid #9797A3', // アンダーラインを追加
              },
            }}
            sx={{
              width: '368px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  display: 'none', // デフォルトの枠線を非表示
                },
              },
              '& .MuiInputLabel-root': {
                display: 'none', // label を非表示
              },
            }}
          />

          <Button
            variant="contained"
            onClick={addNewKeyword}
            disabled={addingKeyword || (newKeyword.trim().length < 2) || groupKeywords.length >= keywordsLimit} // 2文字未満は登録できない
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 'var(--1, 8px) 22px',
              borderRadius: 'var(--borderRadius, 4px)',
              background: '#F1D04C',
              color: '#17171A',
              fontSize: '15px',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.24)',

              // 高さを padding から動的に計算
              height: `calc(var(--1, 8px) * 2 + 1em)`, // 1em はフォントサイズを基準
              lineHeight: 1.4, // 行間
              '&:hover': {
                background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), var(--, #F1D04C)',
              },
              '&:focus': {
                background: 'linear-gradient(0deg, rgba(23, 23, 26, 0.07) 0%, rgba(23, 23, 26, 0.07) 100%), var(--, #F1D04C)'
              },
              '&:disabled': {
                background: '#EEEEF0', // 無効時の色（任意）
                color: '#B9B9C1',
              },
            }}
          >
            登録
          </Button>

        </Box>
      </Box>)}

      {/* 登録済みキーワードの管理コンテナ */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignContent: 'flex-start' }}>
        {/* フィルタやソートの設定部分 */}
        {/* 説明文言 */}
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
          }}
        >
          登録したキーワード
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* フィルタキーワード入力 */}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {/* 検索フィールド */}
            <TextField
              placeholder="キーワードを入力"
              value={searchKeyword}
              onChange={(e) => handleSearchChange(e.target.value)}
              sx={{
                width: '368px',
                '& .MuiInputBase-root': {
                  height: '40px', // 全体の高さを明示
                  padding: '0 8px', // 上下の隙間を調整
                  fontSize: '14px',
                },
                '& .MuiInputBase-input': {
                  height: '40px', // テキスト入力部分の高さ
                  lineHeight: '24px', // テキストの行間（フォントサイズ 14px + 上下 8px）
                  padding: '8px 0', // 上下の余白を均等に
                },
                '& .MuiInputLabel-root': {
                  display: 'none', // label を非表示
                },
              }}
              InputProps={{
                disableUnderline: true, // デフォルトの下線を無効化
                endAdornment: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%', // フィールドの高さに揃える
                      marginLeft: '8px', // アイコンとの余白を設定
                    }}
                  >
                    <SearchIcon />
                  </Box>
                ),
              }}
            />

            {/* ソート順ボタン */}
            <KeywordSortOrderButton sortOrder={sortOrder} setSortOrder={setSortOrder} />
          </Box>
          {/* 選択モード関連のボタンコンテナ */}
          <Box>
            {/* 選択モードの時表示されるボタン */}
            {selectMode && (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              {!isOverKeywordsLimit && (
                <Button
                  onClick={toggleSelectMode}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFF',
                    padding: '4px 16px',
                    transition: 'background 0.3s ease',
                    '&:hover': {
                      background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.07) 0%, #FFFFFF 100%)',
                    },
                    '&:active': {
                      background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.15) 0%, #FFFFFF 100%)',
                    },
                  }}
                  disableRipple
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#46464F',
                    }}
                  >
                    キャンセル
                  </Typography>
                </Button>
              )}

              <Button
                onClick={unselectKeywords}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#FFF',
                  borderRadius: '4px',
                  border: '1px solid #CBCBD0',
                  padding: '4px 16px',
                  cursor: 'pointer',
                  transition: 'background 0.3s ease, border-color 0.3s ease',
                  '&:hover': {
                    background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.07) 0%, #FFFFFF 100%)',
                    border: '1px solid #CBCBD0',
                  },
                  '&:active': {
                    background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.15) 0%, #FFFFFF 100%)',
                    border: '1px solid #656572',
                  },
                }}
                disableRipple
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: '#656572',
                  }}
                >
                  選択解除
                </Typography>
              </Button>

              <Button
                onClick={() =>  setConfirmOpen(true)}
                disableRipple
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#FFF',
                  borderRadius: '4px',
                  border: '1px solid rgba(183, 39, 46, 0.5)',
                  padding: '4px 16px',
                  '&:hover': {
                    background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // ホバー時の背景
                    border: '1px solid rgba(183, 39, 46, 0.5)',
                  },
                  '&:active': {
                    background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // クリック時の背景
                    border: '1px solid #B7272E',
                  },
                  transition: 'background 0.3s ease, border-color 0.3s ease',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: '#B7272E',
                  }}
                >
                  一括削除
                </Typography>
              </Button>
            </Box>
            )}
            {/* 選択モードにするボタン */}
            {!selectMode && currentRole !== 'viewer' && (
              <Button
                onClick={toggleSelectMode}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#DCDCE0',
                  borderRadius: '4px',
                  padding: '4px 16px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: '#46464F',
                  }}
                >
                  選択する
                </Typography>
              </Button>
            )}
          </Box>
        </Box>


        {/* キーワード一覧 */}
        <KeywordChipList
          keywords={filteredKeywords}
          newlyAddedKeywordIds={newlyAddedKeywordIds}
          selectMode={selectMode}
          selectedKeywords={selectedKeywords}
          newsCounts={newsCounts}
          onCheckboxToggle={handleCheckboxToggle}
          onKeywordClick={fetchNewsByKeywordId} // Pass fetch function to handle clicks
        />
      </Box>

      {/* 以下はダイアログなど */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>選択したキーワードを削除しますか？
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} sx={{ color: '#27272C' }}>キャンセル</Button>
          <Button onClick={removeKeywordsFromGroup} sx={{ color: '#B7272E' }}>削除する</Button>
        </DialogActions>
      </Dialog>

      <NewsItemsModal
        open={newsModalOpen}
        onClose={handleNewsItemsModalClose}
        newsItems={selectedNews}
        modalTitle={selectedKeyword}
      />

      {/* Loading Spinner */}
      <Backdrop open={loading || addingKeyword} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />

    </Box>
  );
};

export default KeywordsPanel;
