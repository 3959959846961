import React from 'react';
import {
  Box,
  Button,
  Chip,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Link,
  InputAdornment,
} from '@mui/material';
import CloseIconFilled from '@mui/icons-material/Close';
import SortOrderButton, { SortOrder } from './WebsiteSortOrderButton';
import { ToggleSwitch } from '../shared/ToggleSwitch';
import { ReactComponent as SearchIcon } from '../../assets/icons/SearchIcon.svg'
import { ReactComponent as InfoIconFilled } from '../../assets/icons/InfoIconFilled.svg'
import { NoMaxWidthTooltip } from '../shared/NoMaxWidthTooltip';
import { WebsiteType } from '../../types/types';

type AddWebsitesPanelProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  newWebsiteUrl: string;
  setNewWebsiteUrl: (url: string) => void;
  requestNewWebsite: () => void;
  sortOrderForUnassociated: SortOrder;
  setSortOrderForUnassociated: (order: SortOrder) => void;
  sortedUnassociatedWebsites: WebsiteType[];
  selectedUnAssociatedWebsites: number[];
  handleUnAssociatedWebsiteSelection: (id: number) => void;
  fetchNewsByWebsiteId: (id: number, title: string) => void;
  newsCounts: Record<number, number>;
  showUrls: boolean;
  setShowUrls: (show: boolean) => void;
  setSelectedUnAssociatedWebsites: (ids: number[]) => void;
  addWebsitesToGroup: (ids: number[]) => Promise<void>;
};

const AddWebsitesPanel: React.FC<AddWebsitesPanelProps> = ({
  isOpen,
  setIsOpen,
  newWebsiteUrl,
  setNewWebsiteUrl,
  requestNewWebsite,
  sortOrderForUnassociated,
  setSortOrderForUnassociated,
  sortedUnassociatedWebsites,
  selectedUnAssociatedWebsites,
  handleUnAssociatedWebsiteSelection,
  fetchNewsByWebsiteId,
  newsCounts,
  showUrls,
  setShowUrls,
  setSelectedUnAssociatedWebsites,
  addWebsitesToGroup,
}) => {
  if (!isOpen) return null;

  const canAddWebsiteUrl = (url: string): boolean => {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)*$/;
    return urlRegex.test(url);
  };

  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        right: '16px',
        top: '76px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '400px',
        height: 'calc(100vh - 60px)',
        background: 'lightgray',
        boxShadow: '-4px 0px 8px 0px rgba(0, 0, 0, 0.10)',
        padding: '0px',
        boxSizing: 'border-box',
        gap: 0,
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FFF',
          margin: 0,
          gap: 2,
          boxSizing: 'border-box',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          flex: '1 1 auto',
          overflowY: 'auto',
          padding: '24px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography sx={{ mb: 0, fontSize: '16px', color: '#27272C' }}>
            登録したいWebサイトを入力
          </Typography>
          <Button
            onClick={() => setIsOpen(false)}
            sx={{
              minWidth: '32px',
              minHeight: '32px',
              padding: 0,
              background: 'transparent',
              color: '#777',
              fontSize: '16px',
              lineHeight: '1',
              borderRadius: '50%',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
          >
            <CloseIconFilled />
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: 2,
          }}
        >
          <TextField
            value={newWebsiteUrl}
            onChange={(e) => setNewWebsiteUrl(e.target.value)}
            placeholder="キーワードかURLを入力"
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                height: '40px',
              },
              fontSize: '14px'
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={requestNewWebsite}
            variant="contained"
            disabled={!canAddWebsiteUrl(newWebsiteUrl)}
            sx={{
              backgroundColor: canAddWebsiteUrl(newWebsiteUrl) ? '#F1D04C' : '#EEEEF0',
              color: canAddWebsiteUrl(newWebsiteUrl) ? '#17171A' : '#B9B9C1',
              padding: '8px 22px',
              display: 'flex',
              flexDirection: 'column',
              fontSize: '15px',
              '&:hover': {
                backgroundColor: canAddWebsiteUrl(newWebsiteUrl) ? '#FFD700' : '#EEEEF0',
              },
              '&:disabled': {
                backgroundColor: '#EEEEF0',
                color: '#B9B9C1',
              },
              whiteSpace: 'nowrap'
            }}
          >
            申請
          </Button>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0 }}>
                <Typography mr='-4px' sx={{ color: '#7A7A7A', fontFamily: 'Roboto' }}>url</Typography>
                <ToggleSwitch
                  checked={showUrls}
                  onChange={(e) => setShowUrls(e.target.checked)}
                />
              </Box>
              <SortOrderButton sortOrder={sortOrderForUnassociated} setSortOrder={setSortOrderForUnassociated} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px'
              }}
            >
              <Button
                onClick={() => setSelectedUnAssociatedWebsites([])}
                sx={{
                  display: 'flex',
                  padding: '6px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: '140%', // Matches 19.6px line-height
                  letterSpacing: '0.28px',
                  textTransform: 'uppercase',
                  background: '#FFF',
                  fontSize: '14px',
                  borderRadius: '4px',
                  borderColor: '#CBCBD0',
                  color: '#656572',
                  border: '1px solid var(--BK-300, #CBCBD0)'
                }}
              >
                選択解除
              </Button>
              <Button
                variant="contained"
                disabled={selectedUnAssociatedWebsites.length === 0}
                onClick={async () => {
                  if (selectedUnAssociatedWebsites.length === 0) return;
                    await addWebsitesToGroup(selectedUnAssociatedWebsites);
                    setSelectedUnAssociatedWebsites([]); // 選択をクリア
                    // console.log('登録が完了しました');
                  }}
                sx={{ backgroundColor: '#F1D04C', color: '#17171A', fontSize: '14px', borderRadius: '4px' }}
              >
                登録
              </Button>
            </Box>
          </Box>
          <List
            sx={{
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              gap: '0px',
              flex: '1 0 0',
              alignSelf: 'stretch',
              boxSizing: 'border-box',
            }}
          >
            {sortedUnassociatedWebsites.map((website) => (
              <ListItem
                key={website.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 0,
                  gap: 0,
                  margin: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '4px',
                    width: '100%',
                  }}
                >
                  <Checkbox
                    checked={selectedUnAssociatedWebsites.includes(website.id)}
                    onChange={() => handleUnAssociatedWebsiteSelection(website.id)}
                  />
                  <Box
                    component="img"
                    src={`https://www.google.com/s2/favicons?domain=${website.url}`}
                    alt={`${website.title} logo`}
                    sx={{
                      width: '22px',
                      height: '22px',
                      borderRadius: '24px',
                      objectFit: 'contain',
                    }}
                  />
                  <ListItemText
                    sx={{ fontSize: '12px', margin: 0 }}
                    primary={
                      <Box>
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Link
                          href={website.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{ color: '#0288D1', fontSize: '14px', fontWeight: 600 }}
                        >
                          {website.title}
                        </Link>
                        <Button onClick={() => fetchNewsByWebsiteId(website.id, website.title)}>
                          <Typography>{` (${newsCounts[website.id] || 0})`}</Typography>
                        </Button>
                        {/* ステータス */}
                        {website.status === 'pending' && (
                          <Chip
                            label={<Box sx={{ padding: '0px 6px' }}>申請中</Box>}
                            sx={{
                              backgroundColor: '#40CD00',
                              color: '#FFF',
                              fontSize: '12px',
                              height: '24px',
                              padding: '3px 4px',
                              borderRadius: '100px',
                              '& .MuiChip-label': {
                                padding: 0,
                              },
                            }}
                          />
                        )}
                        {website.status === 'error' && (
                          <Chip
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#FFF',
                                    fontWeight: 400,
                                    padding: '0px 6px',
                                  }}
                                >
                                  エラー
                                </Typography>
                                <NoMaxWidthTooltip
                                  title={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        color: '#FFF',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      <Typography>処理に問題が生じております。</Typography>
                                      <Typography>復旧までしばらくお待ちください。</Typography>
                                      <Typography>
                                        ※解決しない場合は
                                        <Box
                                          component="span"
                                          sx={{
                                            textDecoration: 'underline',
                                          }}
                                        >
                                          こちら
                                        </Box>
                                      </Typography>
                                    </Box>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '16px',
                                      height: '16px',
                                      opacity: 0.7,
                                      cursor: 'pointer',
                                    }}
                                  >
                                      <InfoIconFilled />
                                    </Box>
                                  </NoMaxWidthTooltip>
                                </Box>
                              }
                              sx={{
                                backgroundColor: '#D32F2F',
                                color: '#FFF',
                                fontSize: '12px',
                                height: '24px',
                                padding: '3px 4px',
                                borderRadius: '100px',
                                '& .MuiChip-label': {
                                  padding: 0,
                                },
                              }}
                            />
                          )}
                          {website.status === 'unsupported' && (
                            <Chip
                              label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#FFF',
                                    fontWeight: 400,
                                    padding: '0px 6px',
                                  }}
                                >
                                  対応不可
                                </Typography>
                                <NoMaxWidthTooltip
                                  title={
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'center',
                                        padding: '4px 8px',
                                        borderRadius: '4px',
                                        color: '#FFF',
                                        fontSize: '10px',
                                        textAlign: 'left',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      <Typography>対応ができないサイトです。</Typography>
                                      <Typography>
                                        ※解決しない場合は
                                        <Box
                                          component="span"
                                          sx={{
                                            textDecoration: 'underline',
                                          }}
                                        >
                                          こちら
                                        </Box>
                                      </Typography>
                                    </Box>
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '16px',
                                      height: '16px',
                                      opacity: 0.7,
                                      cursor: 'pointer',
                                    }}
                                  >
                                      <InfoIconFilled />
                                    </Box>
                                  </NoMaxWidthTooltip>
                                </Box>
                              }
                              sx={{
                                backgroundColor: '#D32F2F',
                                color: '#FFF',
                                fontSize: '12px',
                                height: '24px',
                                padding: '3px 4px',
                                borderRadius: '100px',
                                '& .MuiChip-label': {
                                  padding: 0,
                                },
                              }}
                            />
                          )}
                        </Box>
                        {showUrls && (
                          <Typography sx={{ fontSize: '10px', color: '#C2C2C2' }}>{website.url}</Typography>
                        )}
                      </Box>
                    }
                  />
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default AddWebsitesPanel;
