import React from 'react';
import { Typography, Button, Box, Card, CardContent, TextField } from '@mui/material';
import Logo from '../../assets/Logo';
import { useAuth0 } from '@auth0/auth0-react';

interface EmailNotVerifiedProps {
  handleLogout: () => void;
  handleResendEmail: () => void;
}

const EmailNotVerified: React.FC<EmailNotVerifiedProps> = ({ handleLogout, handleResendEmail }) => {
  const { user } = useAuth0();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#555555',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '80%',
          height: '80%',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px',
          gap: '40px',
          backgroundColor: '#FFFFFF'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#17171A',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Logo />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px'
          }}
        >
          <Box
            sx={{
              // display: 'flex',
              // flexDirection: 'column',
              textAlign: 'center',
              color: '#17171A',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '160%',
                letterSpacing: '1.68px'
              }}
            >
              以下のメールアドレスに認証メールを送信しました。
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '160%',
                letterSpacing: '1.68px'
              }}
            >
              メール内のリンクをクリックしてアカウント登録をしてください。
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '320px',
              height: '48px',
              padding: '0px 16px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              borderBottom: '1px solid #CBCBD0',
              backgroundColor: '#EEEEF0',
            }}
          >
            <Typography variant="body1">
              {user?.email || "No email available"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '160%',
              letterSpacing: '1.68px',
              textAlign: 'center'
            }}
          >
            認証メールが届かない場合、
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '160%',
              letterSpacing: '1.68px',
              textAlign: 'center'
            }}
          >
            「認証メールを再送する」ボタンをクリックしてください。
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            onClick={handleResendEmail}
            sx={{
              display: 'flex',
              width: '320px',
              height: '48px',
              padding: '12px 22px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              backgroundColor: '#27272C',
              color: '#FFFFFF',
              boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '140%',
              letterSpacing: '0.32px',
              textTransform: 'uppercase'
            }}
          >
            認証メールを再送する
          </Button>
        </Box>

        <Box>
          <Button
            variant="outlined"
            onClick={handleLogout}
            sx={{
              display: 'flex',
              width: '320px',
              height: '48px',
              padding: '12px 22px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: '0',
              backgroundColor: '#FFFFFF',
              color: '#17171A',
              borderRadius: '4px',
              border: '1px solid #27272C',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '140%',
              letterSpacing: '0.32px',
              textTransform: 'uppercase'
            }}
          >
            トップへ戻る
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

export default EmailNotVerified;
