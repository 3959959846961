import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, CircularProgress, List, ListItem, Button, TextField, Modal, Backdrop } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from '../../GroupContext';
import axios from 'axios';
import { start } from 'repl';

const EmailHistoryPanel = () => {
    const [emailHistories, setEmailHistories] = useState<{ emailId: number; sentAt: string; html: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedEmail, setSelectedEmail] = useState<string | null>(null); // 選択されたメール
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest');
    const [emailHtmlCache, setEmailHtmlCache] = useState<Record<string, string>>({});
    const [loadingEmail, setLoadingEmail] = useState<boolean>(false);
    const startAnchorRef = useRef<HTMLDivElement | null>(null);
    const endAnchorRef = useRef<HTMLDivElement | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const { groupId } = useGroup(); 

    useEffect(() => {
        const fetchEmailOverviews = async () => {
            setLoading(true);
            try {
                const token = await getAccessTokenSilently();

                const query = `
                    query GetEmailHistoryOverview($groupId: Int!) {
                        emailHistoryOverview(groupId: $groupId) {
                            emailId
                            subject
                            sentAt
                        }
                    }
                `;

                const variables = { groupId: groupId };
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_API}/graphql`,
                    { query, variables },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data?.data?.emailHistoryOverview) {
                    setEmailHistories(response.data.data.emailHistoryOverview);
                    setError(null);
                } else {
                    setError('データが見つかりませんでした。');
                }
                
            } catch (err) {
                console.error(err);
                setError('メール履歴の取得に失敗しました。');
            } finally {
                setLoading(false);
            }
        };

        if (groupId) {
            fetchEmailOverviews();
        }
    }, [getAccessTokenSilently, groupId]);

    const handleEmailClick = async (emailId: number) => {
        if (emailHtmlCache[emailId]) {
            setSelectedEmail(emailHtmlCache[emailId]);
            prefetchPreviousEmail(emailId); // 前のメールをバックグラウンドで取得
            return;
        }
        setLoadingEmail(true);

        try {
            const token = await getAccessTokenSilently();
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_API}/graphql`,
                {
                    query: `
                    query GetEmailHtml($emailId: Int!) {
                        emailHtml(emailId: $emailId)
                    }
                `,
                    variables: { emailId },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data?.data?.emailHtml) {
                const html = response.data.data.emailHtml;
                setEmailHtmlCache((prev) => ({ ...prev, [emailId]: html }));
                setSelectedEmail(html);
                prefetchPreviousEmail(emailId); // 前のメールをバックグラウンドで取得
            } else {
                setError('メールHTMLを取得できませんでした。');
            }
        } catch (err) {
            console.error(err);
            setError('メールHTMLの取得に失敗しました。');
        } finally {
            setLoadingEmail(false);
        }
    };


    const prefetchPreviousEmail = async (currentEmailId: number) => {
        const currentIndex = sortedHistories.findIndex((email) => email.emailId === currentEmailId);
        // 一つ前のメールが存在するか確認
        if (currentIndex + 1 < sortedHistories.length) {
            const previousEmail = sortedHistories[currentIndex + 1];

            // 既にキャッシュ済みの場合は何もしない
            if (emailHtmlCache[previousEmail.emailId]) {
                return;
            }

            try {
                const token = await getAccessTokenSilently();
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_API}/graphql`,
                    {
                        query: `
                    query GetEmailHtml($emailId: Int!) {
                        emailHtml(emailId: $emailId)
                    }
                `,
                        variables: { emailId: previousEmail.emailId },
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data?.data?.emailHtml) {
                    const html = response.data.data.emailHtml;
                    setEmailHtmlCache((prev) => ({ ...prev, [previousEmail.emailId]: html }));
                }
            } catch (err) {
                console.error('バックグラウンドでのメール取得に失敗しました。', err);
            }
        }
    };


    const handleSortChange = () => {
        setSortOrder((prev) => (prev === 'newest' ? 'oldest' : 'newest'));
    };

    const filteredHistories = emailHistories.filter((email) => {
        const emailDate = new Date(email.sentAt).setHours(0, 0, 0, 0);
        const startTime = startDate ? startDate.setHours(0, 0, 0, 0) : null;
        const endTime = endDate ? endDate.setHours(0, 0, 0, 0) : null;

        return (!startTime || emailDate >= startTime) && (!endTime || emailDate <= endTime);
    });

    const sortedHistories = [...filteredHistories].sort((a, b) => {
        return sortOrder === 'newest'
            ? new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()
            : new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box
                sx={{
                    padding: 5,
                    backgroundColor: '#FFF',
                    borderRadius: 'var(--1, 8px)',
                    flexGrow: 1,
                    minWidth: '100%',
                    boxSizing: 'border-box',
                }}
            >
                {/* 最上部 */}
                <Box mb={4} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    {/* タイトルとダウンロードボタン */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#17171A',
                                fontSize: '24px',
                                fontWeight: 700,
                                flex: '1 0 0',
                            }}
                        >
                            メール配信履歴
                        </Typography>
                        <Button variant="contained" color="primary">
                            CSVをダウンロード
                        </Button>
                    </Box>

                    {/* サブタイトルと検索フィールド */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {/* 左側: フィルタ */}
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box ref={startAnchorRef}>
                                <DatePicker
                                    label="開始日"
                                    value={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    slotProps={{
                                        popper: {
                                            anchorEl: startAnchorRef.current,
                                            disablePortal: true,
                                        },
                                    }}
                                    slots={{ textField: (props) => <TextField {...props} /> }}
                                />
                            </Box>
                            <Box ref={endAnchorRef}>
                                <DatePicker
                                    label="終了日"
                                    value={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    minDate={startDate ? startDate : undefined}
                                    slotProps={{
                                        popper: {
                                            anchorEl: endAnchorRef.current,
                                            disablePortal: true,
                                        },
                                    }}
                                    slots={{ textField: (props) => <TextField {...props} /> }}
                                />
                            </Box>
                        </Box>
                        {/* 右側: 並び順 */}
                        <Button onClick={handleSortChange}>
                            {sortOrder === 'newest' ? '日付が新しい順' : '日付が古い順'}
                        </Button>
                    </Box>
                </Box>

                {/* メール履歴リスト */}
                {loading ? (
                    <Box></Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : sortedHistories.length === 0 ? (
                    <Typography>メール履歴がありません。</Typography>
                ) : (
                    <List>
                        {sortedHistories.map((email) => (
                            <ListItem
                                key={email.emailId}
                                sx={{ borderBottom: '1px solid #ddd', cursor: 'pointer' }}
                                onClick={() => handleEmailClick(email.emailId)}
                            >
                                <Typography>{new Date(email.sentAt).toLocaleString()}</Typography>
                            </ListItem>
                        ))}
                    </List>
                )}

                {/* モーダル: メール内容 */}
                <Modal
                    open={!!selectedEmail}
                    onClose={() => setSelectedEmail(null)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#FFF',
                            padding: 4,
                            borderRadius: '8px',
                            maxWidth: '80%',
                            maxHeight: '80%',
                            overflow: 'auto',
                        }}
                    >
                        <Button
                            onClick={() => setSelectedEmail(null)}
                            sx={{
                                position: 'absolute',
                                top: 16,
                                right: 16,
                            }}
                        >
                            ✕
                        </Button>
                        <Box dangerouslySetInnerHTML={{ __html: selectedEmail || '' }} />
                    </Box>
                </Modal>
                {/* Loading Spinner */}
                <Backdrop open={loading || loadingEmail} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        </LocalizationProvider>
    );
};

export default EmailHistoryPanel;
