import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
  Backdrop,
  // Snackbar,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from '../../GroupContext';
import { UserType, RoleType, SnackbarType } from '../../types/types';
import NotificationSnackbar from '../shared/NotificationSnackbar';
import UserSortOrderButton from './UserSortOrderButton';
import { ReactComponent as SearchIcon } from '../../assets/icons/SearchIcon.svg';
import GroupUsersList from './GroupUsersList';

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const UsersPanel: React.FC<{
  usersLimit: number;
  onChangePanel: (panelName: string) => void
}> = ({
  usersLimit,
  onChangePanel
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { groupIdx, groupInfo, groupId, planName, currentRole } = useGroup();
  const [users, setUsers] = useState<UserType[]>([]);
  const [loading, setLoading] = useState(false);

  const [newUserEmail, setNewUserEmail] = useState('');
  const [addingUser, setAddingUser] = useState(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });

  const [selectMode, setSelectMode] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [addConfirmOpen, setAddConfirmOpen] = useState(false);

  const [sortOrder, setSortOrder] = useState<'role' | 'name' | 'email' | 'emailEnabled'>('name');
  const [searchUser, setSearchUser] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOverUsersLimit, setIsOverUsersLimit] = useState<boolean>(false);  // ユーザーの上限を超えているかどうか(true: 超えている)

  const isEmailDuplicate = users.some(
    (user) => user.email.toLowerCase() === newUserEmail.trim().toLowerCase()
  )

  const toggleSelectMode = () => {
    setSelectMode(!selectMode)
    // 選択している状態は引き継がない
    unselectUsers()
  };

  // ユーザーの選択を全て解除する
  const unselectUsers = () => {
    setSelectedUsers([])
  }

  const handleUserSelection = (id: number) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id) // チェックを外す
        : [...prevSelected, id] // チェックを付ける
    );
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  }

  const handleToggleEmailEnabled = async (id: number, emailEnabled: boolean) => {
    if (currentRole !== 'admin' && currentRole !== 'editor') {
      setSnackbarState({ open: true, message: '権限がありません', color: 'red', success: false });
      return;
    }

    if (isOverUsersLimit) {
      setSnackbarState({ open: true, message: 'ユーザーの上限を超えています。削除してください。', color: 'red', success: false });
      return;
    }

    const targetUser = users.find((user) => user.id === id);

    // ローカルで先に反映
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, emailEnabled } : user
      )
    );

    // バックエンドへ変更を送信
    const mutation = `
      mutation ToggleEmailEnabled($groupId: Int!, $userId: Int!, $emailEnabled: Boolean!) {
        toggleEmailEnabled(groupId: $groupId, userId: $userId, emailEnabled: $emailEnabled)
      }
    `;
    const variables = {
      groupId,
      userId: id,
      emailEnabled,
    };

    try {
      const token = await getAccessTokenSilently();
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSnackbarState({
        open: true,
        message: `${targetUser?.email || '不明なユーザー'}のメール配信設定を${emailEnabled ? '有効' : '無効'}にしました`,
        color: 'blue',
        success: true,
      });
    } catch (error) {
      console.error('Failed to update email enabled status:', error);

      // エラー時はローカル状態を元に戻す
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === id ? { ...user, emailEnabled: !emailEnabled } : user
        )
      );
      setSnackbarState({
        open: true,
        message: 'メール配信設定の変更に失敗しました',
        color: 'red',
        success: false,
      });
    }
  };


  const fetchUsersByGroup = async () => {
    const query = `
      query GetUsersByGroup($groupId: Int!) {
        usersByGroup(groupId: $groupId) {
          id
          email
          firstName
          lastName
          role
          emailEnabled
        }
      }
    `;
    const variables = { groupId: groupId };

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setUsers(result.data.data.usersByGroup);
    } catch (error) {
      console.error('Failed to fetch users by group:', error);
      setSnackbarState({ open: true, message: 'ユーザーの取得に失敗しました', color: 'red', success: false });
    }
    setLoading(false);
  };

  const validateInputs = () => {
    if (!newUserEmail) {
      setSnackbarState({ open: true, message: 'メールアドレスを入力してください', color: 'red', success: false });
      return false;
    }
    return true;
  };

  const addNewUser = async () => {
    if (!validateInputs()) return;
    // 存在するユーザーは追加リクエストに進まない
    if (isEmailDuplicate) {
      setSnackbarState({
        open: true,
        message: "すでにコレクションに存在するユーザーです",
        color: "red",
        success: false
      }
      )
      return
    }
    setAddConfirmOpen(true);
  };

  const handleConfirmAddUser = async () => {
    setAddConfirmOpen(false); // ダイアログを閉じる
    console.log(usersLimit);
    if (users.length >= usersLimit) {
      // フロントエンドで制限数を超える場合
      setSnackbarState({
        open: true,
        message: `ユーザーの上限（${usersLimit}人）に達しました。`,
        color: 'red',
        success: false,
      });
      return;
    }
    setAddingUser(true);

    const mutation = `
      mutation AddUser($groupId: Int!, $email: String!, $role: String!) {
        addUser(groupId: $groupId, email: $email, role: $role) {
          id
          email
          firstName
          lastName
          role
          emailEnabled
        }
      }
    `;
    const variables = {
      groupId: groupId,
      email: newUserEmail,
      role: "viewer",
    };

    try {
      const token = await getAccessTokenSilently();
      const result = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query: mutation, variables },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.data.errors) {
        const error = result.data.errors[0];
        const errorCode = error.extensions?.code;

        let errorMessage = "ユーザーの追加に失敗しました。";
        if (errorCode === "INVALID_EMAIL_FORMAT") {
          errorMessage = "メールアドレスの形式が正しくありません。";
        } else if (errorCode === "USER_LIMIT_REACHED") { // 制限数超過時
          errorMessage = "グループのユーザー制限数を超えています。";
        } else if (errorCode === "USER_NOT_FOUND") {
          errorMessage = "システムにユーザーが見つかりません。";
        } else if (errorCode === "AUTH0_CREATION_FAILED") {
          errorMessage = "外部サービスでのユーザー作成に失敗しました。";
        }

        setSnackbarState({
          open: true,
          message: errorMessage,
          color: "red",
          success: false,
        });
        return;
      }

      setUsers((prevUsers) => [...prevUsers, result.data.data.addUser]);
      setSnackbarState({
        open: true,
        message: "ユーザーが追加されました。",
        color: "blue",
        success: true,
      });
      setNewUserEmail("")
    } catch (error) {
      console.error("Failed to add user:", error);
      setSnackbarState({
        open: true,
        message: "ネットワークエラーによりユーザー追加に失敗しました。",
        color: "red",
        success: false,
      });
    } finally {
      setAddingUser(false);
      setAddConfirmOpen(false);
    }
  };

  const handleRoleChange = async (id: number, newRole: string) => {
    const currentUserId = user?.["https://newsbee.biz/user_id"];

    if (id === currentUserId) {
      console.warn("You cannot change your own role.");
      setSnackbarState({
        open: true,
        message: "自分の権限を変更することはできません。",
        color: "red",
        success: false,
      });
      return; // 処理を中断
    }

    const targetUser = users.find((user) => user.id === id);

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, role: newRole as UserType['role'] } : user
      )
    );

    const mutation = `
      mutation UpdateUserRole($userId: Int!, $groupId: Int!, $role: String!) {
        updateUserRole(userId: $userId, groupId: $groupId, role: $role) {
          id
          role
        }
      }
    `;

    const variables = {
      userId: id,
      groupId, // 現在の groupId を使用
      role: newRole,
    };

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        {
          query: mutation,
          variables,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSnackbarState({
        open: true,
        message: `${targetUser?.email || '不明なユーザー'}の権限を${newRole === 'admin' ? '管理者' : newRole === 'editor' ? '編集者' : '閲覧者'}に変更しました`,
        color: "blue",
        success: true,
      });

      // 必要に応じてバックエンドのレスポンスで状態を更新
      const updatedUser = response.data.data.updateUserRole;
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedUser.id ? { ...user, role: updatedUser.role } : user
        )
      );
      console.log('User role updated successfully:', updatedUser);
    } catch (error) {
      console.error('Failed to update user role:', error);
      setSnackbarState({
        open: true,
        message: '権限の変更に失敗しました',
        color: 'red',
        success: false,
      });

      // エラー時にロールバック
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === id ? { ...user, role: user.role } : user
        )
      );
    }
  };

  const handleDeleteUser = async () => {
    const mutation = `
      mutation DeleteUsers($groupId: Int!, $userIds: [Int!]!) {
        deleteUsers(groupId: $groupId, userIds: $userIds)
      }
    `;
    const variables = { groupId: groupId, userIds: selectedUsers };
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query: mutation, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const resultMessage = response.data.data.deleteUsers;
      console.log(resultMessage);

      // フロントエンドの状態を更新
      setUsers((prevUsers) => {
        const newUsers = prevUsers.filter((user) => !selectedUsers.includes(user.id));
        const newUserCount = newUsers.length;

        // 状態更新後にリミットチェック
        if (newUserCount <= usersLimit && (planName !== 'business' && planName !== 'business_plus')) {
          // ユーザ数が制限以下になり、かつプランがbusinessでない場合はkeywords画面へ移動
          onChangePanel('keywords');
        }

        return newUsers;
      });
      setSnackbarState({ open: true, message: 'ユーザーをコレクションから削除しました', color: 'blue', success: true });
    } catch (error) {
      console.error('Failed to delete user:', error);
      setSnackbarState({ open: true, message: 'ユーザーの削除に失敗しました', color: 'red', success: false });
    } finally {
      if (users.length <= usersLimit) {
        setIsOverUsersLimit(false);
      }
      setDeleteConfirmOpen(false);
      setLoading(false);
    }
  };

  const selectedEmails = users
    .filter((user) => selectedUsers.includes(user.id))
    .map((user) => user.email);

  const handleCloseSnackbar = () => {
    setSnackbarState((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    const filtered = users
      .filter((user) =>
        user.email.toLowerCase().includes(searchUser.toLowerCase()) ||
        `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchUser.toLowerCase())
      )
      .sort((a, b) => {
        if (sortOrder === 'role') {
          return a.role.localeCompare(b.role); // 権限順
        } else if (sortOrder === 'name') {
          const nameA = `${a.firstName || ''} ${a.lastName || ''}`.trim().toLowerCase();
          const nameB = `${b.firstName || ''} ${b.lastName || ''}`.trim().toLowerCase();

          // 名前が空の場合は下に配置する
          if (!nameA && !nameB) return 0;
          if (!nameA) return 1; // `a` の名前が空なら下に
          if (!nameB) return -1; // `b` の名前が空なら下に

          return nameA.localeCompare(nameB); // アルファベット順
        } else if (sortOrder === 'email') {
          return a.email.localeCompare(b.email); // メールアドレス順
        } else if (sortOrder === 'emailEnabled') {
          return (a.emailEnabled === b.emailEnabled) ? 0 : a.emailEnabled ? -1 : 1; // メール配信有効なものを先に
        }
        return 0; // デフォルトは順序変更なし
      });

    setFilteredUsers(filtered);
  }, [users, searchUser, sortOrder]);

  useEffect(() => {
    if (groupId) {
      fetchUsersByGroup();
    }
  }, [groupId]);

  useEffect(() => {
    if (!groupInfo[groupIdx]) {
      // groupInfoがまだ取得できていない場合はreturnして処理しない
      return;
    }

    console.log("usersLimit", usersLimit);
    console.log("users", users.length);
    if (users.length > usersLimit) {
      setSelectMode(true);
      setIsOverUsersLimit(true);
    } else {
      setIsOverUsersLimit(false);
    }
  }, [groupInfo[groupIdx], users]);

  useEffect(() => {
    if (isOverUsersLimit) {
      setSnackbarState({
        open: true,
        message: `ユーザーの上限（${usersLimit}個）を超えています。削除してください。`,
        color: 'red',
        success: false
      });
    }
  }, [isOverUsersLimit]);

  return (
    <Box sx={{
      padding: 5,
      backgroundColor: '#FFF',
      borderRadius: 'var(--1, 8px)',
      flexGrow: 1,
      minWidth: '100%',
      boxSizing: 'border-box'
    }}>
      {isOverUsersLimit && (
        <Box>
          <Typography
            sx={{
              color: '#FF0000'
            }}
          >
            ユーザーの上限は{usersLimit}人です。削除してください。
          </Typography>
        </Box>
      )}
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '140%', // 33.6px
          }}
        >
          組織管理
        </Typography>
      </Box>

      {(currentRole === 'admin') && (
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#27272C',
              fontFamily: '"Open Sans", sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '140%',
              mb: 2,
            }}
          >
            新しいユーザーを追加
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
            <TextField
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              placeholder="例)newsbees@example.com"
              InputProps={{
                disableUnderline: true,
                sx: {
                  height: '46px',
                  padding: '0 12px',
                  background: newUserEmail.trim()
                    ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), #EEEEF0'
                    : '#EEEEF0',
                  borderRadius: 'var(--borderRadius, 4px) var(--borderRadius, 4px) var(--none, 0px) var(--none, 0px)',
                  alignItems: 'center',
                  borderBottom: '1px solid #9797A3',
                },
              }}
              sx={{
                width: '368px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    display: 'none',
                  },
                },
                '& .MuiInputLabel-root': {
                  display: 'none',
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={addNewUser}
              disabled={addingUser || !isValidEmail(newUserEmail) || users.length >= usersLimit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 'var(--1, 8px) 22px',
                borderRadius: 'var(--borderRadius, 4px)',
                background: isEmailDuplicate ? "#EEEEF0" : '#F1D04C',
                color: isEmailDuplicate ? "#B9B9C1" : '#17171A',
                fontSize: '15px',
                boxShadow: isEmailDuplicate ? "none" : '0px 1px 2px 0px rgba(0, 0, 0, 0.24)',
                // 高さを padding から動的に計算
                height: `calc(var(--1, 8px) * 2 + 1em)`, // 1em はフォントサイズを基準
                lineHeight: 1.4, // 行間
                '&:hover': {
                  background: isEmailDuplicate
                    ? "#EEEEF0"
                    : 'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), var(--, #F1D04C)',
                },
                '&:focus': {
                  background: 'linear-gradient(0deg, rgba(23, 23, 26, 0.07) 0%, rgba(23, 23, 26, 0.07) 100%), var(--, #F1D04C)'
                },
                '&:disabled': {
                  background: '#EEEEF0', // 無効時の色（任意）
                  color: '#B9B9C1',
                },
              }}
            >
              {addingUser ? '登録中...' : isEmailDuplicate ? "登録不可" : '登録'}
            </Button>
          </Box>
        </Box>
      )}

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignContent: 'flex-start',
        width: '860px'
      }}>
        <Typography
          sx={{
            color: '#17171A',
            fontFamily: '"Open Sans", sans-serif',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%',
          }}
        >
          登録したユーザー
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* フィルタキーワード入力 */}
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {/* 検索フィールド */}
            <TextField
              placeholder="ユーザーを入力"
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              sx={{
                width: '368px',
                '& .MuiInputBase-root': {
                  height: '40px', // 全体の高さを明示
                  padding: '0 8px', // 上下の隙間を調整
                  fontSize: '14px',
                },
                '& .MuiInputBase-input': {
                  height: '40px', // テキスト入力部分の高さ
                  lineHeight: '24px', // テキストの行間（フォントサイズ 14px + 上下 8px）
                  padding: '8px 0', // 上下の余白を均等に
                },
                '& .MuiInputLabel-root': {
                  display: 'none', // label を非表示
                },
              }}
              InputProps={{
                disableUnderline: true, // デフォルトの下線を無効化
                endAdornment: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%', // フィールドの高さに揃える
                      marginLeft: '8px', // アイコンとの余白を設定
                    }}
                  >
                    <SearchIcon />
                  </Box>
                ),
              }}
            />
            <UserSortOrderButton sortOrder={sortOrder} setSortOrder={setSortOrder} />
          </Box>
          <Box>
            {/* 選択モードの時表示されるボタン */}
            {selectMode && (
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                {!isOverUsersLimit && (
                  <Button
                    onClick={toggleSelectMode}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: '#FFF',
                      padding: '4px 16px',
                      transition: 'background 0.3s ease',
                      '&:hover': {
                        background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.07) 0%, #FFFFFF 100%)',
                      },
                      '&:active': {
                        background: 'linear-gradient(180deg, rgba(70, 70, 79, 0.15) 0%, #FFFFFF 100%)',
                      },
                    }}
                    disableRipple
                  >
                    <Typography
                      sx={{
                        fontSize: '13px',
                        color: '#46464F',
                      }}
                    >
                      キャンセル
                    </Typography>
                  </Button>
                )}

                <Button
                  onClick={unselectUsers}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFF',
                    borderRadius: '4px',
                    border: '1px solid #CBCBD0',
                    padding: '4px 16px',
                    cursor: 'pointer',
                    transition: 'background 0.3s ease, border-color 0.3s ease',
                    '&:hover': {
                      background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.07) 0%, #FFFFFF 100%)',
                      border: '1px solid #CBCBD0',
                    },
                    '&:active': {
                      background: 'linear-gradient(180deg, rgba(203, 203, 208, 0.15) 0%, #FFFFFF 100%)',
                      border: '1px solid #656572',
                    },
                  }}
                  disableRipple
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#656572',
                    }}
                  >
                    選択解除
                  </Typography>
                </Button>

                <Button
                  onClick={() => setDeleteConfirmOpen(true)}
                  disableRipple
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#FFF',
                    borderRadius: '4px',
                    border: '1px solid rgba(183, 39, 46, 0.5)',
                    padding: '4px 16px',
                    '&:hover': {
                      background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // ホバー時の背景
                      border: '1px solid rgba(183, 39, 46, 0.5)',
                    },
                    '&:active': {
                      background: 'linear-gradient(180deg, rgba(183, 39, 46, 0.07) 0%, #FFFFFF 100%)', // クリック時の背景
                      border: '1px solid #B7272E',
                    },
                    transition: 'background 0.3s ease, border-color 0.3s ease',
                    cursor: 'pointer',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '13px',
                      color: '#B7272E',
                    }}
                  >
                    一括削除
                  </Typography>
                </Button>
              </Box>
            )}
            {/* 選択モードにするボタン */}
            {!selectMode && (currentRole === 'admin') && (
              <Button
                onClick={toggleSelectMode}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#DCDCE0',
                  borderRadius: '4px',
                  padding: '4px 16px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: '#46464F',
                  }}
                >
                  選択する
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
        <GroupUsersList
          displayedUsers={filteredUsers}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          totalUsers={filteredUsers.length}
          selectMode={selectMode}
          selectedUsers={selectedUsers}
          handleUserSelection={handleUserSelection}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleToggleEmailEnabled={handleToggleEmailEnabled}
          handleRoleChange={handleRoleChange}
        />
      </Box>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>
          選択したユーザーを削除しますか？
        </DialogTitle>
        <Box sx={{ padding: '16px' }}>
          <Typography sx={{ fontSize: '14px', color: '#666', marginBottom: '16px' }}>削除対象のユーザー:</Typography>
          <ul>
            {selectedEmails.map((email) => (
              <li key={email}>
                <Typography sx={{ fontSize: '14px', color: '#000' }}>{email}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} sx={{ color: '#27272C' }}>キャンセル</Button>
          <Button onClick={handleDeleteUser} sx={{ color: '#B7272E' }}>削除する</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addConfirmOpen} onClose={() => setAddConfirmOpen(false)}>
        <DialogTitle>
          次のユーザーを追加しますか？
          <Typography sx={{ mt: 1, fontSize: 14 }}>
            メール: {newUserEmail}
            <br />
            権限: 閲覧者
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setAddConfirmOpen(false)} sx={{ color: "#27272C" }}>
            キャンセル
          </Button>
          <Button onClick={handleConfirmAddUser} sx={{ color: "#4CAF50" }}>
            追加
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={loading || addingUser} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <NotificationSnackbar
        open={snackbarState.open}
        onClose={handleCloseSnackbar}
        message={snackbarState.message}
        success={snackbarState.success}
        color={snackbarState.color}
      />

    </Box>
  );
};

export default UsersPanel;
