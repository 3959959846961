import React from 'react';
import { Dialog, Box, Typography, DialogActions, Button } from '@mui/material';

interface DeleteCollectionDialogProps {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const DeleteCollectionDialog: React.FC<DeleteCollectionDialogProps> = ({
    open,
    onConfirm,
    onCancel,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            sx={{
                borderRadius: '4px',
            }}
            PaperProps={{
                sx: {
                    width: '444px',
                },
            }}
        >
            <Box sx={{ padding: '16px 24px' }}>
                <Typography sx={{ color: '#B7272E' }}>
                    コレクションを削除すると今までの履歴も消えてしまいます！
                </Typography>
                <Typography sx={{ color: '#B7272E', marginTop: '8px' }}>
                    本当に削除してよろしいですか？
                </Typography>
            </Box>
            <DialogActions sx={{ padding: '16px 24px', justifyContent: 'flex-end' }}>
                <Button
                    onClick={onConfirm}
                    autoFocus
                    sx={{
                        color: '#27272C',
                        fontSize: '14px',
                    }}
                >
                    削除する
                </Button>
                <Button
                    onClick={onCancel}
                    sx={{
                        color: '#B7272E',
                        fontSize: '14px',
                        fontWeight: 600,
                    }}
                >
                    キャンセル
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCollectionDialog;
