import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { ReactComponent as InfoIconFilled } from '../../assets/icons/InfoIconFilled.svg'
import { ReactComponent as RSSIcon } from '../../assets/icons/RSSIcon.svg'
import { NoMaxWidthTooltip } from '../shared/NoMaxWidthTooltip';
import { WebsiteType } from '../../types/types';

type GroupWebsitesListProps = {
  displayedWebsites: WebsiteType[];
  selectMode: boolean;
  selectedWebsites: number[];
  handleWebsiteSelection: (id: number) => void;
  fetchNewsByWebsiteId: (id: number, title: string) => void;
  newsCounts: Record<number, number>;
  showUrls: boolean;
};


const GroupWebsitesList: React.FC<GroupWebsitesListProps> = ({
  displayedWebsites,
  selectMode,
  selectedWebsites,
  handleWebsiteSelection,
  fetchNewsByWebsiteId,
  newsCounts,
  showUrls,
}) => {
  return (
    <List
      sx={{
      mt: 1,
      padding: '16px 0px',
      borderRadius: '4px',
      border: '1px solid #CBCBD0',
      display: 'flex',
      flexDirection: 'column',
      gap: '0px',
      flex: '1 1 auto',
      alignSelf: 'stretch',
      boxSizing: 'border-box',
      maxHeight: '100%',
      overflow: 'auto',
      }}
    >
      {displayedWebsites.map((website) => (
        <ListItem
          key={website.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0px 24px',
            gap: 0,
            margin: 0,
            '&:hover': {
              backgroundColor: '#F5F5F5',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '4px',
            }}
          >
            {/* 一括削除用チェックボックス */}
            {selectMode && (
              <Checkbox
                checked={selectedWebsites.includes(website.id)}
                onChange={() => handleWebsiteSelection(website.id)}
              />
            )}
            {/* ロゴ画像 */}
            <Box
              component="img"
              src={`https://www.google.com/s2/favicons?domain=${website.url}`}
              alt={`${website.title} logo`}
              sx={{
                width: '22px',
                height: '22px',
                borderRadius: '24px',
                objectFit: 'contain',
              }}
            />
            {/* Webサイト情報 */}
            <ListItemText
              sx={{ fontSize: '12px', margin: 0 }}
              primary={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    {/* タイトル */}
                    <Link
                      href={website.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: '#0288D1', fontSize: '14px', fontWeight: 600 }}
                    >
                      {website.title}
                    </Link>

                    {/* RSSである場合の表示 */}
                    {website.rss && (<RSSIcon />)}

                    {/* ステータス */}
                    {website.status === 'pending' && (
                      <Chip
                        label={<Box sx={{ padding: '0px 6px' }}>申請中</Box>}
                        sx={{
                          backgroundColor: '#40CD00',
                          color: '#FFF',
                          fontSize: '12px',
                          height: '24px',
                          padding: '3px 4px',
                          borderRadius: '100px',
                          '& .MuiChip-label': {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                    {website.status === 'error' && (
                      <Chip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#FFF',
                                fontWeight: 400,
                                padding: '0px 6px',
                              }}
                            >
                              エラー
                            </Typography>
                            <NoMaxWidthTooltip
                              title={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    color: '#FFF',
                                    fontSize: '10px',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <Typography>処理に問題が生じております。</Typography>
                                  <Typography>復旧までしばらくお待ちください。</Typography>
                                  <Typography>
                                    ※解決しない場合は
                                    <Box
                                      component="span"
                                      sx={{
                                        textDecoration: 'underline',
                                      }}
                                    >
                                      こちら
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                              placement="top"
                              arrow
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '16px',
                                  height: '16px',
                                  opacity: 0.7,
                                  cursor: 'pointer',
                                }}
                              >
                                <InfoIconFilled />
                              </Box>
                            </NoMaxWidthTooltip>
                          </Box>
                        }
                        sx={{
                          backgroundColor: '#D32F2F',
                          color: '#FFF',
                          fontSize: '12px',
                          height: '24px',
                          padding: '3px 4px',
                          borderRadius: '100px',
                          '& .MuiChip-label': {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                    {website.status === 'unsupported' && (
                      <Chip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: '#FFF',
                                fontWeight: 400,
                                padding: '0px 6px',
                              }}
                            >
                              対応不可
                            </Typography>
                            <NoMaxWidthTooltip
                              title={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    color: '#FFF',
                                    fontSize: '10px',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <Typography>対応ができないサイトです。</Typography>
                                  <Typography>
                                    ※解決しない場合は
                                    <Box
                                      component="span"
                                      sx={{
                                        textDecoration: 'underline',
                                      }}
                                    >
                                      こちら
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                              placement="top"
                              arrow
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  width: '16px',
                                  height: '16px',
                                  opacity: 0.7,
                                  cursor: 'pointer',
                                }}
                              >
                                <InfoIconFilled />
                              </Box>
                            </NoMaxWidthTooltip>
                          </Box>
                        }
                        sx={{
                          backgroundColor: '#D32F2F',
                          color: '#FFF',
                          fontSize: '12px',
                          height: '24px',
                          padding: '3px 4px',
                          borderRadius: '100px',
                          '& .MuiChip-label': {
                            padding: 0,
                          },
                        }}
                      />
                    )}

                    {/* 件数ボタン */}
                    <Button onClick={() => fetchNewsByWebsiteId(website.id, website.title)}>
                      <Typography>{` (${newsCounts[website.id] || 0} 件)`}</Typography>
                    </Button>
                  </Box>
                  {/* URL表示 */}
                  {showUrls && (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        color: '#C2C2C2',
                      }}
                    >
                      {website.url}
                    </Typography>
                  )}
                </Box>
              }
            />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default GroupWebsitesList;
