import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { RoleType, PlanNameType } from './types/types';
import {
  Box, CircularProgress
} from '@mui/material';


// Define Context Type
interface GroupContextType {
  groupIdx: number;
  setGroupIdx: (idx: number) => void;
  groupInfo: GroupInfo[];
  refreshGroupInfo: () => Promise<void>;
  groupName: string;
  groupId: number | null;
  planName: PlanNameType | null;
  currentRole: RoleType | null;
  loading: boolean;
}

// Define the structure of GroupInfo
interface GroupInfo {
  id: number;
  groupName: string;
  role: RoleType;
  planName: PlanNameType | null;
}

// Create Context
const GroupContext = createContext<GroupContextType | undefined>(undefined);

// Provider Component
export const GroupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [groupIdx, setGroupIdx] = useState<number>(0);
  const [groupInfo, setGroupInfo] = useState<GroupInfo[]>([]);
  const { getAccessTokenSilently, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Manage loading state

  // Function to fetch groupInfo from the backend using GraphQL
  const refreshGroupInfo = async () => {
    console.log('refreshGroupInfo');
    // Ensure user metadata is available
    if (!user || !isAuthenticated || user.email_verified === false) {
      console.log('user not found or email not verified or not authenticated');
      return;
    }

    setLoading(true);

    const query = `
    query GetUserGroups {
      getGroupInfo {
        id
        groupName
        role
        planName
      }
    }
    `;

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API}/graphql`,
        { query },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const groupInfo = response.data.data.getGroupInfo;
      setGroupInfo(groupInfo);
    } catch (error) {
      console.error('Failed to fetch group info:', error);
      if (error instanceof Error) {
        if (error.message.includes("Missing Refresh Token")) {
          await loginWithRedirect();
        }
      } else {
        console.error("An unexpected error occurred", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshGroupInfo();
  }, [isAuthenticated]);

  // Compute the current groupName
  const groupName = groupInfo?.[groupIdx]?.groupName || '';
  const groupId = groupInfo?.[groupIdx]?.id || null;
  const planName = groupInfo?.[groupIdx]?.planName || null;
  const currentRole = groupInfo?.[groupIdx]?.role || null;

  return (
    <GroupContext.Provider value={{
      groupIdx,
      setGroupIdx,
      groupInfo,
      refreshGroupInfo,
      groupName,
      groupId,
      planName,
      currentRole,
      loading
    }}>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      {children}
    </GroupContext.Provider>
  );
};

// Hook to Use Context
export const useGroup = () => {
  const context = useContext(GroupContext);
  if (!context) {
    throw new Error('useGroup must be used within a GroupProvider');
  }
  return context;
};

export type { GroupInfo };