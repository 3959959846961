import React, { useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useGroup } from './GroupContext';
import CollectionsInfoDisplay from './components/collections/CollectionsInfoDisplay';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import {
  Button, Box, CircularProgress, Backdrop, AppBar, CardContent, Drawer, List, ListItem, Typography
} from '@mui/material';
import { ReactComponent as LogoutIcon } from './assets/icons/LogoutIcon.svg';
import { ReactComponent as ManageSearchIcon } from './assets/icons/ManageSearchIcon.svg';
import { ReactComponent as LanguageIcon } from './assets/icons/LanguageIcon.svg';
import { ReactComponent as ContactMailIcon } from './assets/icons/GroupsIcon.svg';
import { ReactComponent as DownArrow } from './assets/icons/DownArrow.svg'
import { History } from '@mui/icons-material';
import KeywordsPanel from './components/keywords/KeywordsPanel';
import WebsitesPanel from './components/websites/WebsitesPanel';
import UsersPanel from './components/users/UsersPanel';
import EmailHistoryPanel from './components/email/EmailHistoryPanel';
import EmailNotVerified from './components/email/EmailNotVerified';
import PricingTable from './components/payment/PricingTable';
import { NoMaxWidthTooltip } from './components/shared/NoMaxWidthTooltip';
import Logo from './assets/Logo';
import { SnackbarType } from './types/types';
import './colors.css'

function App() {
  const { isAuthenticated, isLoading, user, loginWithRedirect, logout, error } = useAuth0();
  const location = useLocation();
  const [selectedPanel, setSelectedPanel] = useState<string>('keywords');
  const [snackbarState, setSnackbarState] = useState<SnackbarType>({ open: false, message: '', color: 'blue', success: true });
  const { groupName, groupId, planName, currentRole, groupInfo } = useGroup();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const { getAccessTokenSilently } = useAuth0();
  const [clientSecret, setClientSecret] = useState('');
  const [isPricingTableOpen, setIsPricingTableOpen] = useState(false);
  const [usersCount, setUsersCount] = useState<number>(0);

  const fetchUsersCounts = async () => {
    console.log("fetchUsersCounts");
    if (!groupId) return;
    const token = await getAccessTokenSilently();
    const query = `
      query GetUsersCount($groupId: Int!) {
        usersByGroup(groupId: $groupId) {
          id
        }
      }
    `;
    const variables = { groupId };
    try {
      const result = await axios.post(`${process.env.REACT_APP_BACKEND_API}/graphql`, { query, variables }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      const usersData = result.data.data.usersByGroup;
      setUsersCount(usersData.length);
    } catch (e) {
      console.error("Failed to fetch users count", e);
    }
  };

  useEffect(() => {
    if (groupId ) {
      fetchUsersCounts();
    }
  }, [groupId]);

  useEffect(() => {
    if (error) {
      console.error('Authentication error', error);
      setSnackbarState({ open: true, message: '認証に失敗しました。もう一度試してください。', color: 'red', success: false });
    }
  }, [error]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("iss")) {
      loginWithRedirect();
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const handleOpenSubscriptionForm = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/customer_portal`
      const token = await getAccessTokenSilently();
      const response = await axios.post(url, { group_id: groupId }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
      );

      if (response.data.type === "portal") {
        // Redirect the user to the billing portal
        console.log("response.data.url: ", response.data.url);
        window.location.href = response.data.url;
      } else if (response.data.type === "checkout") {
        console.log("response.data.client_secret: ", response.data.client_secret);
        setClientSecret(response.data.client_secret);
        setIsPricingTableOpen(true);
      }
    } catch (error) {
      console.error("Error during plan selection:", error);
    }
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    window.location.reload();
  };

  const handleResendEmail = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/resend_verification_email`
      const token = await getAccessTokenSilently();
      const response = await axios.post(url, { email: user?.email }, {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
      console.log("handleResendEmail");
    } catch (error) {
      console.error("Error during resend email:", error);
    }
  };

  // ボタンの外側のスタイル
  const getOuterButtonStyles = (isSelected: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: 'var(--font-size-075-rem, 12px)',
    borderRadius: 'var(--borderRadius, 4px)',
    background: isSelected ? '#484850' : 'transparent',
    transition: 'background 0.3s ease',
  });

  // ボタンのアイコン部分のスタイル
  const innerButtonStyles = {
    width: 'var(--4, 32px)',
    height: 'var(--4, 32px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    color: '#fff',
  };

  // default, hover, focusedの状態ごとのボタンの背景色を返す
  const getButtonStylesByState = (state: 'default' | 'hover' | 'focused', baseColor: string) => {
    const shadow = '0px 2px 0px 0px rgba(0, 0, 0, 0.06)';

    switch (state) {
      case 'hover':
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case 'focused':
        return {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), ${baseColor}`,
          boxShadow: shadow,
        };
      case 'default':
      default:
        return {
          background: baseColor,
          boxShadow: shadow,
        };
    }
  };

  const handleChangePanel = (panelName: string) => {
    setSelectedPanel(panelName);
  }

  const renderSelectedPanel = () => {
    switch (selectedPanel) {
      case 'keywords':
        return <KeywordsPanel />;
      case 'websites':
        return <WebsitesPanel />;
      case 'users':
        return (
          currentRole && user && (
            <UsersPanel usersLimit={userLimit} onChangePanel={handleChangePanel} />
          )
        );
      case 'history':
        return <EmailHistoryPanel />;
      default:
        return <KeywordsPanel />;
    }
  };

  const getUserLimitByPlan = (plan: string | null) => {
    const planNameLower = plan?.toLowerCase() || 'free';
    switch(planNameLower) {
      case 'business_plus':
        return parseInt(process.env.REACT_APP_LIMIT_USERS_BUSINESS_PLUS_PLAN || '200', 10);
      case 'business':
        return parseInt(process.env.REACT_APP_LIMIT_USERS_BUSINESS_PLAN || '100', 10);
      case 'large':
        return parseInt(process.env.REACT_APP_LIMIT_USERS_LARGE_PLAN || '1', 10);
      case 'small':
        return parseInt(process.env.REACT_APP_LIMIT_USERS_SMALL_PLAN || '1', 10);
      default:
        return parseInt(process.env.REACT_APP_LIMIT_USERS_FREE_PLAN || '1', 10);
    }
  };

  const userLimit = getUserLimitByPlan(planName);

  // businessプランの場合はユーザ画面表示可能
  // businessプランでなくても、現在ユーザ数がuserLimitを超えている（例:ダウングレード前の残存ユーザが多い）場合はユーザ画面を表示
  const canAccessUserPanel = ((planName === 'business' || planName === 'business_plus') || (usersCount > userLimit)) && (currentRole === 'admin' || currentRole === 'editor');

  const allowedPanels = [
    { panel: 'keywords', icon: <ManageSearchIcon fontSize="large" />, baseColor: '#484850', hint: 'キーワード' },
    { panel: 'websites', icon: <LanguageIcon fontSize="large" />, baseColor: '#484850', hint: 'ウェブサイト' },
    { panel: 'history', icon: <History fontSize="large" />, baseColor: '#484850', hint: 'メール履歴' },
    { panel: 'users', icon: <ContactMailIcon fontSize="large" />, baseColor: '#484850',
      condition: canAccessUserPanel, hint: 'ユーザー' },
    //{ panel: 'admin', icon: <AdminPanelSettings fontSize="large" />, baseColor: '#484850', role: 'admin' },
  ].filter(({ condition }) => (condition === undefined || condition));  // NOTE: Stripeの制限適用
  
  useEffect(() => {
    if (selectedPanel === 'users' && !canAccessUserPanel) {
      setSelectedPanel('keywords');
    }
  }, [canAccessUserPanel, selectedPanel]);

  if (user && !user.email_verified) {
    return (
      <div>
        <EmailNotVerified handleLogout={handleLogout} handleResendEmail={handleResendEmail}/>
      </div>
    );
  }


  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isAuthenticated) {
    return (
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        {/* トップバー */}
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
            backgroundColor: '#000',
            color: '#fff',
            minHeight: '60px',
            height: '60px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 40px 8px 24px',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
            background: 'var(--BK-10000, #17171A)',
          }}
        >
          {/* News Beeのロゴ */}
          <Logo />
          {/* プラン表示＆Stripeのcustomer portalへ遷移する部分 */}
          <Box sx={{ marginLeft: 'auto' }}><Button onClick={handleOpenSubscriptionForm}>
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '140%',
                letterSpacing: '0.28px'
              }}
            >{planName}プラン</Typography>
            </Button>
          </Box>
          {/* コレクション名表示部分 */}
          <Box sx={{ padding: 0, marginLeft: '24px' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#FFF',
                fontFamily: '"Open Sans", sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '140%',
                letterSpacing: '0.32px',
                cursor: 'pointer', // Ensure text and icon are clickable
              }}
              onClick={handleOpenModal} // Handle click event for both text and icon
            >
              <Box
                component="span"
                sx={{
                  color: "#FFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  letterSpacing: "0.32px",
                  display: "inline-flex",
                  height: "48px",
                  padding: "8px 4px 8px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: "var(--borderRadius, 4px)", // Rounded corners
                  background: "transparent", // 初期背景色
                  "&:hover": {
                    background: "rgba(255, 255, 255, 0.04)", // ホバー時
                  },
                  "&:active": {
                    background: "rgba(255, 255, 255, 0.10)", // クリック時
                  },
                }}
              >
                {groupName || ""}
                <DownArrow />
              </Box>
            </Typography>
          </Box>
        </AppBar>

        <Box sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh'
        }}>
          {/* サイドバー */}
          <Drawer
            variant="permanent"
            sx={{
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: 80,
                height: 'calc(100vh - 60px)', // トップバーを除いた高さ
                boxSizing: 'border-box',
                background: 'var(--BK-10000, #17171A)',
                color: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                padding: 'var(--5, 40px) var(--font-size-075-rem, 12px)',
                marginTop: '60px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                flexGrow: 1,
                alignItems: 'center',
                padding: 0,
              }}
            >
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--4, 32px)',
                  alignItems: 'center',
                  padding: 0,
                  width: '100%',
                }}
              >
                {allowedPanels.map(({ panel, icon, baseColor, hint }) => (
                  <ListItem
                    key={panel}
                    sx={{
                      padding: 0,
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Button
                      onClick={() => setSelectedPanel(panel)}
                      sx={{
                        ...getOuterButtonStyles(selectedPanel === panel),
                        '&:hover': getButtonStylesByState('hover', baseColor),
                        '&:focus': getButtonStylesByState('focused', baseColor),
                        minWidth: '56px',
                      }}
                    >
                      <Box sx={innerButtonStyles}><NoMaxWidthTooltip title={hint}>{icon}</NoMaxWidthTooltip></Box>
                      </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Button
                onClick={() => handleLogout()}
                sx={{
                  ...getOuterButtonStyles(false),
                  '&:hover': getButtonStylesByState('hover', '#484850'),
                  '&:focus': getButtonStylesByState('focused', '#484850'),
                  minWidth: '56px',
                }}
              >
                <Box sx={innerButtonStyles}>
                  <NoMaxWidthTooltip title="ログアウト">
                    <LogoutIcon />
                  </NoMaxWidthTooltip>
                </Box>
              </Button>
            </Box>
          </Drawer>

          <CollectionsInfoDisplay
            open={isModalOpen}
            onClose={handleCloseModal}
            groupInfo={groupInfo}
          />

          <PricingTable clientSecret={clientSecret} open={isPricingTableOpen} onClose={() => setIsPricingTableOpen(false)} />

          {/* 本体コンテンツ */}
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              padding: 'var(--2, 16px)',
              flex: 1, // 高さを全体に引き伸ばす
              overflow: 'auto', // スクロールを許可
              marginLeft: '80px', // サイドバーの幅分右に寄せる
              marginTop: '60px', // トップバーの高さ分下に寄せる
              backgroundColor: '#EEEEF0',
              width: '100%'
            }}
          >
            {renderSelectedPanel()}
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
}

export default App;
